import { Row } from '@components/common/layoutUtils';
import { urls } from '@config/configConstants';
import t from '@i18n/t';
import { authActions } from '@state/auth';
import colors from '@utils/colors';
import { RegularText } from '@utils/typography';
import React, { FC } from 'react';
import { SemesterUser } from 'shovel-lib/types';
import styled from 'styled-components';

type Props = {
  user: SemesterUser;
  logout: typeof authActions.logout;
};

const CoachSessionBar: FC<Props> = ({ user, logout }) => {
  return (
    <Container>
      <RegularText color={colors.white}>You are logged in as {user.email}</RegularText>
      <CoachSessionBackLink
        onClick={() => {
          logout({ redirectUrl: urls.coachPlatformUrl });
        }}
      >
        {t.BACK_TO_SHOVEL_COACH}
      </CoachSessionBackLink>
    </Container>
  );
};

export default CoachSessionBar;

const Container = styled(Row)`
  height: 30px;
  background-color: ${colors.primaryPurple};
  justify-content: center;
`;

const CoachSessionBackLink = styled(RegularText)`
  display: block;
  margin-left: 15px;
  color: ${colors.white};
  cursor: pointer;
  text-decoration: underline;

  &:hover {
    opacity: 0.8;
  }
`;
