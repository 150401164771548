import {
  EmailWrapper,
  LoginRequest,
  RedeemAccessRequest,
  ResetPasswordRequest,
  SignUpRequest,
  TokenResponse
} from 'shovel-lib/types';
import { actionCreator, asyncActionsCreator } from '../common/utils';

const asyncActions = asyncActionsCreator('@@user');
const action = actionCreator('@@user');

export const login = asyncActions<LoginRequest, void, string>('LOGIN');

export const refreshToken = asyncActions<void, void, string>('REFRESH_TOKEN');

export const logout = action<void | { redirectUrl?: string }>('LOG_OUT');

export const forgotPassword = asyncActions<EmailWrapper, string, string>('FORGOT_PASSWORD');

export const resetPassword = asyncActions<ResetPasswordRequest, void, string>('RESET_PASSWORD');

export const setPassword = asyncActions<RedeemAccessRequest, void, string>('SET_PASSWORD');

export const signUp = asyncActions<SignUpRequest, TokenResponse, string>('SIGN_UP');

export const signUpMobile = asyncActions<Omit<SignUpRequest, 'password'>, void, string>('SIGN_UP_MOBILE');

export const signUpOrganization = asyncActions<
  SignUpRequest & { organizationId: number; organizationEmailDomainName?: string },
  TokenResponse,
  string
>('SIGN_UP_ORGANIZATION');

export const signUpOrganizationMobile = asyncActions<
  Omit<SignUpRequest, 'password'> & { organizationId: number; organizationEmailDomainName?: string },
  TokenResponse,
  string
>('SIGN_UP_ORGANIZATION_MOBILE');

export const clearAuthActionResult = action<void>('CLEAR_AUTH_RESULT');

export const setOrganizationRegistrationLink = action<string>('SET_ORGANIZATION_REGISTRATION_LINK');

export const resetTrialFromDashboard = asyncActions<void, TokenResponse, string>('RESET_TRIAL_FROM_DASHBOARD');

export const resendActivationEmail = asyncActions<EmailWrapper, string, string>('RESEND_ACTIVATION_EMAIL');
