import React, { useState } from 'react';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import t from '../../../i18n/t';
import { useGoogleApi } from './useGoogleApi';
import MaterialIcon from '../icons/MaterialIcon';
import colors from '../../../utils/colors';
import TippyTooltip from '../tooltip/TippyTooltip';
import styled from 'styled-components';
import Button from '../buttons/Button';
import { closeConfirmationDialog } from '../../../state/common/actions';
import { unsyncGoogleCalendar } from '../../../state/googleCalendar/actions';
import { googleCalendarApi } from '../../../api/googleCalendarApi';
import { Column } from '@components/common/layoutUtils';
import { MediumText } from '@utils/typography';
import { MenuItem } from '@components/common/ContextMenu';

type PropsFromDispatch = {
  unsyncGoogleCalendar: typeof unsyncGoogleCalendar.request;
  closeDialog: typeof closeConfirmationDialog;
};

type Props = PropsFromDispatch & {
  alreadyConnected?: boolean;
  reconnect?: boolean;
  openSyncHelp?: any;
};

const allOptions = [
  {
    name: 'Google',
    link: ''
  },
  {
    name: 'Apple',
    link: 'https://courses.shovelapp.io/lessons/how-to-view-apple-calendar-inside-shovel/'
  },
  {
    name: 'Outlook',
    link: 'https://courses.shovelapp.io/lessons/how-to-view-outlook-calendar-inside-shovel/ '
  }
];

const optionsForAlreadyConnected = [
  {
    name: 'Apple',
    link: 'https://courses.shovelapp.io/lessons/how-to-view-apple-calendar-inside-shovel/'
  },
  {
    name: 'Outlook',
    link: 'https://courses.shovelapp.io/lessons/how-to-view-outlook-calendar-inside-shovel/'
  }
];

const onItemClick = (
  option: { name: string; link: string },
  approveAccessToGoogle: any,
  setVisible: any,
  openSyncHelp: any
) => {
  if (option.name === 'Google') {
    approveAccessToGoogle(true);
  } else {
    openSyncHelp?.(option.link);
  }
  setVisible(false);
};

function AddCalendarButton({ alreadyConnected, reconnect, closeDialog, unsyncGoogleCalendar, openSyncHelp }: Props) {
  const { approveAccessToGoogle } = useGoogleApi();
  const [visible, setVisible] = useState(false);
  const options = alreadyConnected ? optionsForAlreadyConnected : allOptions;
  if (reconnect) {
    return (
      <Button
        onClick={() => {
          googleCalendarApi.disconnect().then(response => {
            if (response.ok) {
              approveAccessToGoogle(true, true);
              closeDialog();
            }
          });
        }}
        filled
      >
        {t.RECONNECT}
      </Button>
    );
  }
  return (
    <TippyTooltip
      target={
        <Wrapper style={{ height: 18 }}>
          <TippyTooltip
            target={
              <div>
                <MaterialIcon name={'add_circle'} color={colors.primaryPurple} onClick={() => setVisible(true)} size={18} />
              </div>
            }
            content={t.CONNECT_GOOGLE_CALENDAR}
          />
        </Wrapper>
      }
      width={'200px'}
      arrow={false}
      interactive
      trigger={'click'}
      lightTheme
      position={'right'}
      noPadding
      controlled
      visible={visible}
      onClickOutside={() => setVisible(false)}
      content={
        <Column>
          {options.map(option => (
            <Item key={option.name} onClick={() => onItemClick(option, approveAccessToGoogle, setVisible, openSyncHelp)}>
              <MediumText strong>{option.name}</MediumText>
            </Item>
          ))}
        </Column>
      }
    />
  );
}

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      closeDialog: closeConfirmationDialog,
      unsyncGoogleCalendar: unsyncGoogleCalendar.request
    },
    dispatch
  );

export default connect(null, mapDispatchToProps)(AddCalendarButton);

const Wrapper = styled.span`
  i {
    transition: opacity 0.2s;
    &:hover {
      opacity: 0.75;
    }
  }
`;

const Item = styled(MenuItem)`
  padding: 10px 20px;
`;
