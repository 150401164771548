import { all, call, delay, put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import { push } from 'connected-react-router';

import * as actions from './actions';
import { apiCall, callApi } from '../common/operations';
import { getErrorMessage } from '../../api/network';
import { courseApi, termApi } from '../../api';
import { getCurrent, getDetailedSemester, getUserSemesters } from '../semester/actions';
import * as semesterSelectors from '../semester/selectors';
import { alert } from '@components/common/snackbarNotifications/Notifications';
import t from '../../i18n/t';
import { getSemesterStatus } from '../semester/selectors';
import { AUTOSAVE_INDICATOR_TIMEOUT } from '../../utils/taskUtils';
import { activityApi } from 'shovel-lib';
import storage from '../../utils/storage';
import { startOnboardingListener } from '../settings/actions';
import { SemesterStatus } from 'shovel-lib/types';
import { goToHomeLink } from '../../components/navigation/navigationHelpers';
import { settingsSelectors } from '../settings';
import { initialUserSettings } from '../settings/reducers';
import { automaticUserSettings } from '../settings/operations';
import { DEMO } from '../../utils/constants/routes';
import mobileBridgeApi, { OutputType } from '../../api/mobileBridgeApi';
import { isMobileApp } from '../../utils/screenUtils';
import { getGoogleEvents, getSavedCalendars } from '../googleCalendar/actions';
import { closeIcsUpdatesDialog } from '../ics/actions';
import { getEventsList } from '../events/actions';
import { getPersonalizationSettings } from '../settings/selectors';
import weekApi from '@api/weekApi';

function* findCountries() {
  const apiCall = call(termApi.findCountries);
  const { ok, ...response } = yield call(callApi, apiCall);
  if (ok) {
    yield put(actions.findCountries.success(response.data));
  } else {
    yield put(actions.findCountries.failure(getErrorMessage(response)));
  }
}

function* findTimezonesByCountry({ payload }: { payload: any }) {
  const apiCall = call(termApi.findTimezonesByCountry, payload);
  const { ok, ...response } = yield call(callApi, apiCall);
  if (ok) {
    yield put(actions.findTimezonesByCountry.success(response.data));
  } else {
    yield put(actions.findTimezonesByCountry.failure(getErrorMessage(response)));
  }
}

function* setupTerm(action: ReturnType<typeof actions.setupTerm.request>) {
  const semesterId = yield select(semesterSelectors.getId);
  const { redirect, countryCode, ...data } = action.payload;
  const apiCall = call(termApi.termSetup, semesterId, data);
  const { ok, ...response } = yield call(callApi, apiCall);
  if (ok) {
    const badges = yield select(settingsSelectors.getUserBadges);
    if (!badges) {
      // it's an old user that didn't have badges but is about to get them with the new term
      yield put(startOnboardingListener());
    }
    const hasSettings = yield select(settingsSelectors.hasSettings);
    const { startOfDay, time24Format, dateFormat, firstDayOfWeek } = initialUserSettings;
    if (!hasSettings && countryCode) {
      yield call(automaticUserSettings, { startOfDay, time24Format, dateFormat, firstDayOfWeek }, countryCode);
    }

    yield put(actions.setupTerm.success(response.data));
    yield put(getCurrent.request());
    if (redirect) {
      /*TODO: check this*/
      yield delay(AUTOSAVE_INDICATOR_TIMEOUT);
      yield put(push(goToHomeLink(semesterId)));
    } else {
      yield put(getDetailedSemester.request());
      yield put(getUserSemesters.request());
    }
  } else {
    yield put(actions.setupTerm.failure(getErrorMessage(response)));
  }
}

function* updateTerm(action: ReturnType<typeof actions.updateTerm.request>) {
  const semesterId = yield select(semesterSelectors.getId);
  const apiCall = call(termApi.termSetup, semesterId, action.payload);
  const { ok, ...response } = yield call(callApi, apiCall);
  if (ok) {
    yield put(actions.updateTerm.success(response.data));
    /*in order to success/failure button be seen*/
    yield delay(AUTOSAVE_INDICATOR_TIMEOUT);
    yield put(getCurrent.request());
    yield put(getDetailedSemester.request());
    yield put(getUserSemesters.request());
  } else {
    yield put(actions.updateTerm.failure(getErrorMessage(response)));
  }
}

function* possibleTermStateChange(action: ReturnType<typeof actions.possibleTermStateChange>) {
  const { fromState, toState, isDemo, isFirstSemester } = action.payload;
  const state = yield select(getSemesterStatus);
  const semesterId = yield select(semesterSelectors.getId);
  if (state === fromState) {
    yield put(actions.changeTermState.request({ state: toState, isFirstSemester }));

    if (isMobileApp && toState === SemesterStatus.ACTIVITY_SETUP_DONE) {
      yield call(mobileBridgeApi.sendMessage, {
        type: OutputType.QUICK_SETUP_COMPLETED
      });
    }
    // this condition is just in case if they directly in browser go to demo page
    // so that after click on btn can go the home page
  } else if (isDemo) {
    yield put(push(goToHomeLink(semesterId)));
  }
}

function* changeTermState(action: ReturnType<typeof actions.changeTermState.request>) {
  const semesterId = yield select(semesterSelectors.getId);
  const apiCall = call(termApi.changeTermState, semesterId, action.payload);
  const { ok, ...response } = yield call(callApi, apiCall);
  if (ok) {
    yield put(actions.changeTermState.success(action.payload.state));
    // if mobile app, skip continuing to next step
    // as mobile app will redirect web view into current app
    if (isMobileApp) {
      return;
    }
    if (action.payload.state !== SemesterStatus.CREATED && action.payload.state !== SemesterStatus.SEMESTER_SETUP_DONE) {
      yield put(getSavedCalendars.request());
    }
    if (
      !action.payload.isFirstSemester &&
      (action.payload.state === SemesterStatus.QUICK_SETUP_DONE ||
        action.payload.state === SemesterStatus.ACTIVITY_SETUP_DONE)
    ) {
      yield put(push(goToHomeLink(semesterId)));
    }
    if (action.payload.isFirstSemester && action.payload.state === SemesterStatus.ACTIVITY_SETUP_DONE) {
      yield put(push(DEMO));
    }
  } else {
    yield put(actions.changeTermState.failure(getErrorMessage(response)));
  }
}

function* updateMinimumStudyTimeBlock(action: ReturnType<typeof actions.updateMinimumStudyTimeBlock.request>) {
  const semesterId = yield select(semesterSelectors.getId);
  const value = action.payload;
  const apiCall = call(termApi.updateMinimumStudyTimeBlock, semesterId, { value });
  const { ok, ...response } = yield call(callApi, apiCall);
  if (ok) {
    yield put(actions.updateMinimumStudyTimeBlock.success(value));
  } else {
    yield put(actions.updateMinimumStudyTimeBlock.failure(getErrorMessage(response)));
  }
}

function* deleteTerm(action: ReturnType<typeof actions.deleteTerm.request>) {
  const apiCall = call(termApi.deleteTerm, action.payload);
  const { ok, ...response } = yield call(callApi, apiCall);
  if (ok) {
    yield put(actions.deleteTerm.success(action.payload));
  } else {
    yield put(actions.deleteTerm.failure(getErrorMessage(response)));
    alert(t[getErrorMessage(response)]);
  }
}

function* setAwakeTimes(action: ReturnType<typeof actions.setAwakeTimes.request>) {
  const semesterId = yield select(semesterSelectors.getId);
  const apiCall = call(termApi.setAwakeTimes, semesterId, action.payload);
  const { ok, ...response } = yield call(callApi, apiCall);
  if (ok) {
    yield put(actions.setAwakeTimes.success(action.payload));
    storage.setAwakeTimeValues(action.payload);
  } else {
    yield put(actions.setAwakeTimes.failure(getErrorMessage(response)));
    alert(t[getErrorMessage(response)]);
  }
}

function* createBatchCourses(action: ReturnType<typeof actions.createBatchCourses.request>) {
  const semesterId = yield select(semesterSelectors.getId);
  const settings = yield select(getPersonalizationSettings);
  const response = yield apiCall(courseApi.createBatchCourses, semesterId, {
    ...action.payload,
    //@ts-ignore
    defaultDaysToStartAhead: settings.daysAhead
  });
  if (response.ok) {
    yield put(actions.createBatchCourses.success(response.data));
    yield put(getGoogleEvents.request());
    yield put(closeIcsUpdatesDialog());
    if (action.payload.triggerUpdate || action.payload.forceIcsFilesUpdate) {
      yield put(getEventsList.request({}));
    }
  } else {
    yield put(actions.createBatchCourses.failure(response));
    alert(t[getErrorMessage(response)]);
  }
}

function* createBatchActivities(action: ReturnType<typeof actions.createBatchActivities.request>) {
  const semesterId = yield select(semesterSelectors.getId);
  const response = yield apiCall(activityApi.createBatchActivities, semesterId, action.payload);
  if (response.ok) {
    yield put(actions.createBatchActivities.success(response.data));
  } else {
    yield put(actions.createBatchActivities.failure(response));
    alert(t[getErrorMessage(response)]);
  }
}

function* createDefaultActivities() {
  const semesterId = yield select(semesterSelectors.getId);
  const response = yield apiCall(weekApi.createDefaultActivities, semesterId);
  if (response.ok) {
    yield put(actions.createDefaultActivities.success(response.data));
  } else {
    yield put(actions.createDefaultActivities.failure(response));
    alert(t[getErrorMessage(response)]);
  }
}

function* copyActivitiesFromPreviousSemester(action: ReturnType<typeof actions.copyActivitiesFromPreviousSemester.request>) {
  const semesterId = yield select(semesterSelectors.getId);
  const response = yield apiCall(weekApi.copyActivities, semesterId, action.payload);
  if (response.ok) {
    yield put(actions.copyActivitiesFromPreviousSemester.success(response.data));
  } else {
    yield put(actions.copyActivitiesFromPreviousSemester.failure(response));
    alert(t[getErrorMessage(response)]);
  }
}

export default function*() {
  yield all([
    takeEvery(actions.findCountries.request, findCountries),
    takeLatest(actions.findTimezonesByCountry.request, findTimezonesByCountry),
    takeLatest(actions.setupTerm.request, setupTerm),
    takeLatest(actions.updateTerm.request, updateTerm),
    takeLatest(actions.changeTermState.request, changeTermState),
    takeLatest(actions.updateMinimumStudyTimeBlock.request, updateMinimumStudyTimeBlock),
    takeLatest(actions.deleteTerm.request, deleteTerm),
    takeEvery(actions.possibleTermStateChange, possibleTermStateChange),
    takeLatest(actions.setAwakeTimes.request, setAwakeTimes),
    takeLatest(actions.createBatchCourses.request, createBatchCourses),
    takeLatest(actions.createBatchActivities.request, createBatchActivities),
    takeLatest(actions.createDefaultActivities.request, createDefaultActivities),
    takeLatest(actions.copyActivitiesFromPreviousSemester.request, copyActivitiesFromPreviousSemester)
  ]);
}
