import { ApiResponse } from 'apisauce';
import { network } from './network';

export type StudentCoachDto = {
  firstName: string;
  accessCode: string;
};

// todo: remove after updating shovel-lib
async function getCoursesWebsiteJwt() {
  return network.get('/courses-website-jwt');
}

async function giveAccessToCoach(accessCode: string): Promise<ApiResponse<void>> {
  return network.post('/give-access', { accessCode });
}

async function revokeCoachAccess(accessCode: string): Promise<ApiResponse<void>> {
  return network.post('/revoke-access', { accessCode });
}

async function getStudentCoaches(): Promise<ApiResponse<StudentCoachDto[]>> {
  return network.get('/my-coaches');
}

export default {
  getCoursesWebsiteJwt,
  giveAccessToCoach,
  revokeCoachAccess,
  getStudentCoaches
};
