import React, { FC } from 'react';
import Popup from 'reactjs-popup';
import styled from 'styled-components';
import colors from '../../../utils/colors';
import { MediumText } from '../../../utils/typography';
import { commonArrowCSS, commonPopupCSS, popupContentStyle } from '../../../utils/popupUtils';
import { Column, Row } from '../layoutUtils';
import MaterialIcon from '../icons/MaterialIcon';
import t from '../../../i18n/t';
import { connect } from 'react-redux';
import { resetOnboarding, toggleOnboardingBar } from '../../../state/settings/actions';
import Toggle from '../Toggle';
import { getShowOnboardingBar, getUserBadges } from '../../../state/settings/selectors';
import { RootState } from '@state/types';
import { BadgesInfo } from '../../../state/settings/types';
import TippyTooltip from '../tooltip/TippyTooltip';
import { EmojiPreview } from '@components/common/emoji/EmojiPreview';
import { NavIconWrapper } from '@components/navigation/partials/LogoutIcon';

type Props = PropsFromState & PropsFromDispatch & { className?: string };

type PropsFromState = {
  showOnboardingBar: boolean;
  badgesInfo: BadgesInfo | null;
};

type PropsFromDispatch = {
  resetOnboarding: typeof resetOnboarding;
  toggleOnboardingBar: typeof toggleOnboardingBar;
};

const LearningCenter: FC<Props> = ({ showOnboardingBar, badgesInfo, resetOnboarding, toggleOnboardingBar, className }) => {
  return (
    <Wrapper>
      <Popup
        trigger={
          <div>
            <TippyTooltip
              target={
                <Icon className={className}>
                  <MaterialIcon className={'navigation-icon'} name={'help_outline'} size={22} outline />
                </Icon>
              }
              content={t.HELP}
              position={'right-start'}
            />
          </div>
        }
        on={'click'}
        position={'right bottom'}
        arrowStyle={{ display: 'none' }}
        contentStyle={{ ...popupContentStyle, width: '270px', zIndex: 1000 }}
      >
        {close => (
          <Content>
            {/*{links.map(link => (*/}
            {/*<Item key={link.title} onClick={() => window.open(link.url, '_blank')}>*/}
            {/*<InfoIcon name={'error'} color={colors.warning} size={16} />*/}
            {/*<Link>{link.title}</Link>*/}
            {/*</Item>*/}
            {/*))}*/}
            {/*<Item onClick={() => window.open('https://howtostudyincollege.com/shovel-study-planner/', '_blank')}>*/}
            {/*<RegularText>👉</RegularText>*/}
            {/*<Link>{t.OVERVIEW_VIDEO}</Link>*/}
            {/*</Item>*/}
            {/*<Item onClick={() => openLink('https://howtostudyincollege.com/learning-center/')}>*/}
            {/*  <MediumText className={'info-icon'}>💡</MediumText>*/}
            {/*  <Link>{t.HELP_WITH_FEATURES}</Link>*/}
            {/*</Item>*/}
            {/*<Item onClick={() => openLink('https://howtostudyincollege.com/learning-center/new-and-improved/')}>*/}
            {/*  <MediumText className={'info-icon'}>🌟</MediumText>*/}
            {/*  <Link>{t.WHATS_NEW}</Link>*/}
            {/*</Item>*/}
            <Item
              onClick={() => openLink('https://shovelapp.io/wp-content/uploads/2022/06/How-To-Be-A-Successful-Student.pdf')}
            >
              <EmojiPreview emoji={'📘'} />
              <Link className={'info-link'}>{t.E_BOOK_TEXT}</Link>
            </Item>
            <Item onClick={() => openLink('https://courses.shovelapp.io/courses/how-to-use-shovel-knowledge-base/')}>
              <EmojiPreview emoji={'🤓'} />
              <Link className={'info-link'}>{t.LEARN_ABOUT_FEATURES}</Link>
            </Item>
            <Separator />
            {/* <Item
              onClick={() => {
                openLink('https://www.reddit.com/r/ShovelApp/ ');
              }}
            >
              <EmojiPreview emoji={'🎤'} />
              <Link className={'info-link'}>{t.JOIN_REDDIT}</Link>
            </Item>
            <Separator />
            <Item
              onClick={() => {
                openLink('https://forms.gle/g9axq82FsubBRoLL9');
              }}
            >
              <EmojiPreview emoji={'💡'} />
              <Link className={'info-link'}>{t.SUBMIT_FEEDBACK}</Link>
            </Item>
            <Item
              onClick={() => {
                openLink('https://forms.gle/vEQnYtEQrq97j5ws8');
              }}
            >
              <EmojiPreview emoji={'🐛'} />
              <Link className={'info-link'}>{t.SUBMIT_A_BUG}</Link>
            </Item>
            <Separator /> */}
            {/*<Item*/}
            {/*  onClick={() => {*/}
            {/*    resetOnboarding();*/}
            {/*    close();*/}
            {/*  }}*/}
            {/*>*/}
            {/*  /!*<MediumText>↩️</MediumText>*!/*/}
            {/*  <Link>{t.RESET_WALKTHROUGH_HINTS}</Link>*/}
            {/*</Item>*/}
            {badgesInfo && (
              <Item>
                <OnboardingTourToggle
                  label={t.ONBOARDING_TOUR}
                  onChange={() => toggleOnboardingBar()}
                  active={showOnboardingBar}
                  reverse={true}
                />
              </Item>
            )}
          </Content>
        )}
      </Popup>
    </Wrapper>
  );
};

const openLink = (link: string) => {
  window.open(link, '_blank');
};

const mapStateToProps = (state: RootState) => ({
  showOnboardingBar: getShowOnboardingBar(state),
  badgesInfo: getUserBadges(state)
});

export default connect(mapStateToProps, {
  resetOnboarding: resetOnboarding,
  toggleOnboardingBar: toggleOnboardingBar
})(LearningCenter);

const Wrapper = styled(Column)`
  .popup-arrow {
    ${commonArrowCSS}
  }
  .popup-content {
    ${commonPopupCSS}
  }
`;

const Icon = styled(NavIconWrapper)`
  margin-bottom: 10px;
`;

const Content = styled.div`
  padding: 10px;
  box-sizing: border-box;
`;

const Link = styled(MediumText)`
  color: ${colors.primaryPurple};
`;

const Item = styled(Row)`
  padding: 5px;
  cursor: pointer;
  &:hover {
    opacity: 0.75;
  }
  .info-link {
    margin-left: 6px;
  }
`;

const InfoIcon = styled(MaterialIcon)`
  transform: rotate(180deg);
`;

const Separator = styled.div`
  border-top: 1px solid ${props => props.theme.borderColor};
  margin: 5px 0;
`;

const OnboardingTourToggle = styled(Toggle)`
  ${MediumText} {
    color: ${colors.primaryPurple};
  }
`;

const OnlineMarker = styled.span`
  height: 8px;
  width: 8px;
  margin-left: 2px;
  margin-right: 10px;
  background-color: ${colors.positive};
  border-radius: 50%;
  display: inline-block;
`;
