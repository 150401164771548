import React from 'react';
// todo temporary copy of all translations

const PINK_COLOR = '#ff63b6';

const t = {
  // Login
  SHOVEL: 'Shovel',
  LOGIN: 'Log In',
  LOG_OUT: 'Log Out',
  LOGIN_TITLE: 'Log in to your account',
  LOGIN_DONT_HAVE_AN_ACC: 'Don’t have an account?',
  FORGOT_PASSWORD: 'Forgot password?',
  DONT_HAVE_ACCOUNT: `Don't have an account?`,
  SIGN_UP: 'Sign up',
  HEY: 'Hey',
  CONGRATS: 'Woohoo! Your Shovel account has been created.',
  ACCESS_MOBILE: (linkColor: string, strongColor: string = PINK_COLOR) => {
    return (
      <>
        Do your initial setup on a <span style={{ color: strongColor }}>laptop or desktop computer.</span>
        <br />
        <br />
        On your <span style={{ color: strongColor }}>computer</span>, go to:
        <br />
        👉 <span style={{ color: linkColor }}>dig.shovelapp.io</span>
        <br />
        <br />
        We require this because it is easier and faster to set up on a larger screen. 🙂
        <br />
        <br />
        <br />
        <br />
        Once you do your initial setup on a larger screen, use the mobile app.
      </>
    );
  },
  LINK_TO_SETUP: 'dig.shovelapp.io/-/login',
  SETUP_DESC_0: `
  Thanks for signing up. You have a lot to do in school and it wouldn't make sense to set up on this small screen.

  `,
  SETUP_DESC_1: `Please open the link below on your computer or tablet.`,
  SETUP_DESC_2: `See you on a bigger screen 🙂`,
  SETUP_DESC_3: `
  - Your Shovel Team`,

  // Calendar
  EMPTY_DATA: 'This is empty date!',

  HOME_TAB_TEXT: 'Home',
  PILE_TO_DO_TAB_TEXT: 'To Do',
  PILE_DO_LATER_TAB_TEXT: 'Do Later',
  PILE_COMPLETED_TAB_TEXT: 'Completed',
  CALENDAR_TAB_TEXT: 'Agenda',
  GRADES_TAB_TEXT: 'Grades',
  PROFILE_TAB_TEXT: 'Account',
  CALENDAR_AGENDA_TAB_TEXT: 'Agenda',
  CALENDAR_DAY_TAB_TEXT: 'Day',
  ADD_TAB_TEXT: 'Add',
  ADD_NEW: 'Add new',
  ADD_RANGE: 'ADD PAGE RANGE',

  // Grades
  GRADES: 'Grades',
  OVERALL_GRADE: 'Overall Grade',
  WEIGHT: 'Weight',
  GRADE_POINTS: 'Grade (points)',
  GRADE_PERCATANGE: 'Grade (%)',
  // Profile
  MY_PROFILE: 'My Profile',

  // Pile Api
  TASK_1_TITLE: 'title1',
  TASK_1_SUBTITLE: 'subtitle1',
  TASK_1_COURSE_NAME: 'Math',

  // Forgot Password labels
  FORGOT_PASSWORD_TITLE: 'Forgot your password?',
  FORGOT_PASSWORD_SUBTITLE: 'Reset password.',
  FORGOT_PASSWORD_SUBTEXT: 'Enter your email and we will send you a link for resetting your password!',
  FORGOT_PASSWORD_BUTTON: 'Send',

  // Desktop App Setup
  DESKTOP_APP_SETUP_TEXT: 'To use Shovel mobile you have to finish setting up at least one course in the Web App.',
  DESKTOP_APP_HEADER_1: 'Don’t have the web-app',
  DESKTOP_APP_HEADER_2: 'set up?',
  DESKTOP_APP_SETUP_STEP_1: 'Go to howtostudyincollege.com',
  DESKTOP_APP_SETUP_STEP_2: 'Get Shovel web app free',
  DESKTOP_APP_SETUP_STEP_3: 'Set up your time and workload',
  DESKTOP_APP_SETUP_STEP_4: 'Come back here',

  // Add Event
  EVENT_NAME_PLACEHOLDER: 'Event name',
  EVENT_COMMUTE_BEFORE_LABEL: 'Before (optional)',
  EVENT_COMMUTE_AFTER_LABEL: 'After (optional)',
  SELECT_COMMITMENT_TYPE: 'Select commitment type',
  SELECT_COMMITMENT_CLASS: 'Select course name',
  SELECT_COMMITMENT_ACTIVITY: 'Select activity name',
  SELECT_COMMITMENT_MEAL: 'Select meal name',
  COMMITMENT_DETAILS_LABEL: 'Details',
  COMMITMENT_SESSION_TYPE_LABEL: 'Session type',
  COMMITMENT_SESSION_TYPE_PLACEHOLDER: 'Lecture,Session,Lab...?',
  COMMITMENT_DETAILS_ADD_PLACEHOLDER: 'Anything else to add?',
  COMMITMENT_DETAILS_REMEMBER_PLACEHOLDER: 'Anything else to remember?',
  COMMITMENT_DETAILS_FUN_PLACEHOLDER: 'Go have Fun!',
  COMMITMENT_LOCATION_LABEL: 'Location',
  COMMITMENT_LOCATION_PLACEHOLDER: 'Building, classroom...',
  EVENT_LOCATION_PLACEHOLDER: 'Zoom link, Home, Classroom...',
  COMMITMENT_TYPE: 'Commitment type',
  MEAL_NAME: 'Meal name',
  ACTIVITY_NAME: 'Activity name',
  CLASS: 'Class',
  ACTIVITY: 'Activity',
  MEAL: 'Meal',
  OPTIONAL: 'optional',
  COMMUTE: 'Commute time in minutes:',

  // Pile task actions
  TIMER: 'Timer',

  // Pile
  PILE_DO_LATER_HEADING: 'Saved for later',
  //TODO: Petr should check this message
  PILE_EMPTY_MESSAGE: 'No tasks found',
  PILE_COMPLETED_HEADING: 'Completed',

  HAVE: 'Have',
  NEED: 'Need',
  CUSHION: 'Cushion',
  SPENT: 'Spent',
  PLANNED: 'Planned',
  UNPLANNED: 'Unplanned',
  DUE: 'Due',
  TEST_DATE: 'Test date',
  TOTAL_NEED: 'Total need',
  COMPLETED: 'Completed',

  // Pile Filter
  FILTER: 'Filter',
  CATEGORY: 'Category',
  APPLY: 'Apply',

  // Storytelling
  SKIP: 'Skip',
  GET_STARTED: 'Get Started',
  STORYTELLING_TITLE_1: 'The first real time study planner.',
  STORYTELLING_DESCRIPTION_1:
    'Shovel is a study planner that lets you see if you have enough time to get all your studies done.',
  STORYTELLING_TITLE_2: 'Don’t juggle your time. Plan it!',
  STORYTELLING_DESCRIPTION_2: 'When are you awake? When are you busy? When can you study? Shovel knows it all!',
  STORYTELLING_TITLE_3: 'Take control of your workload.',
  STORYTELLING_DESCRIPTION_3: 'Estimate.Track. Adjust. Staying on top of your work has never been this easy!',

  // Timer
  START: 'Start',
  STOP: 'Stop',
  PAUSE: 'Pause',
  RESUME: 'Resume',
  NUMBER_OF_PAGES_READ: 'Pages read',
  TIME_SPENT_READING: 'Time it took',
  PAGES: 'Pages',
  PAGE: 'Page',
  DELETE_RECORDED_TIME: 'Delete recorded time',
  PLACEHOLDER_HH_MM: '00h 00m',
  EST_TIME_PER_PAGE: 'Est. time per page',
  PER_PAGE: 'per page',
  PAGES_SHOULD_HAVE_READ: 'Number of pages I should have read',
  AVG_TIME_PER_PAGE: 'Time per Page',
  WILL_BE_USED_AS_NEW: 'will be used as new',
  ESTIMATE: 'Estimate',
  FOR_FUTURE_READINGS_FROM: 'for future readings from',
  USE_AVG_AS_NEW_EST: `Use today's time per page as new estimate`,
  USE_AS_NEW_EST: 'Use as my new estimate',
  MIN: 'min',
  DONT_SAVE: `Don't save`,
  SAVE: 'Save',
  LOAD: 'Load',
  CHANGE: 'Change',
  PAGES_READ: 'pages read',
  PAGES_LEFT: 'pages left',
  USED: 'used',
  TIME_LEFT: 'time left',
  STILL_NEEDED: 'Still need',
  NEEDED: 'needed',
  TIME_USED_DURING_SESSION: 'Time used during this session',
  ADD_MORE_TIME_TO_STILL_NEEDED: 'Add more time to the time I still need',
  MARK_COMPLETE: 'Mark complete',
  MARK_COMPLETE_WARNING: 'All future planned time related to these tasks will be freed!',
  MARK_TO_DO: 'Mark as to do',

  // Task Types
  TEST: 'Test',
  ASSIGNMENT: 'Assignment',
  READING: 'Reading',
  EXERCISE: 'Exercise',
  UNGRADED: 'Ungraded',
  EXTRA_TASK: 'Ungraded',

  // Modal
  RESET: 'Reset',

  //
  LESS_THAN_A_MINUTE: 'less than a minute',
  HOUR: 'hour',
  HOURS: 'hours',
  MINUTE: 'minute',
  MINUTES: 'minutes',
  DAY: 'day',
  DAYS: 'days',
  AHEAD: 'ahead',
  IN: 'in',
  AGO: 'ago',

  // Planner
  PLANNER: 'Planner',

  // Plus button options
  ADD: 'Add',
  ADD_EVENT: 'Add Event',
  ADD_ONE_OFF_COMMITMENT: 'Add One-Off Commitment',
  ADD_COMMITMENT: 'Add Commitment',
  ADD_TASK: 'Add a task',
  ADD_TO_SCHEDULE: 'Add to schedule',
  ADD_READING_TASK: 'Add reading',
  ADD_TEST_TASK: 'Add test',
  ADD_ASSIGNMENT_TASK: 'Add assignment',
  ADD_UNGRADED_TASK: 'Add ungraded',

  EDIT_EVENT: 'Edit Event',
  EDIT_ONE_OFF_COMMITMENT: 'Edit One-Off Commitment',
  EDIT_COMMITMENT: 'Edit Commitment',
  EDIT_TASK: 'Edit task',

  DUE_DATE_AND_TIME: 'Due date and time',
  FIRST_DUE: `First one due`,
  FIRST_TEST_DUE: `First test date`,
  START_EACH_DAYS_AHEAD: 'Start each (days ahead)',
  START_EACH_DAYS_AHEAD_TOOLTIP:
    'Shovel will only look for available study time for each task between the day you want to get started and the due date.',
  FIRST_DUE_DATE_AND_TIME: `First one due date & time`,
  START_DAYS_AHEAD: 'Start (days ahead)',
  PLANNED_STUDY: 'Planned Study',
  USE: 'Use',
  STATUS: 'Status',
  TO_DO: 'To Do',
  DO_LATER: 'Overdue',
  DELETE: 'Delete',
  CLOSE: 'Close',
  DELETE_CONFIRMATION_MESSAGE: 'Do you want to delete this?',
  APPLY_CHANGES_GOING_FORWARD: 'Apply changes going forward',
  STARTS: 'Starts',
  ENDS: 'Ends',
  EDIT: 'Edit',
  PLAN_TO_USE: 'Plan to use',
  ADD_A_SUBTASK: 'Add a subtask',
  SUBTASKS: 'Subtasks',
  START_WORKING: 'Start',
  START_STUDYING: 'Start studying',
  START_READING: 'Start',
  START_AHEAD_PLACEHOLDER: '0 days ahead',
  START_AHEAD_INFO:
    'Set how many days before the due date\nyou want to start working on this task.\n(Change default in settings > personalization)',
  START_AHEAD_TOOLTIP:
    'Shovel will only look for available study time for this task between the day you want to get started and the due date.',
  SELECT_COURSE: 'Select course',
  SELECT_IN_WHAT_CLASS: 'Select in what course',
  TIME_NEEDED: 'Time needed',
  TIME_NEEDED_PLACEHOLDER: '0h 00m',
  TIME_NEEDED_TOOLTIP: 'How much study time will you need to get this task done?',
  SELECT_GRADE_UNIT: 'Select grade unit',
  SELECT_READING_SOURCE: 'Select reading source',
  TITLE: 'Title',
  UNGRADED_TITLE_PLACEHOLDER: "What's the task?",
  READING_SOURCE: 'Reading source',
  GRADE_UNIT_TASK_DETAILS_LABEL: 'Details',
  GRADE_UNIT_TASK_DETAILS_PLACEHOLDER: 'What do you have to do?',
  READING_TASK_DETAILS_LABEL: 'Description',
  READING_TASK_DETAILS_PLACEHOLDER: 'Anything else to add?',
  FROM_PAGE: 'From page',
  TO_PAGE: 'To page',
  PAGES_READ_LABEL: 'Pages read',
  TIME_IT_TOOK: 'Time it took',
  TIME_IT_TOOK_OPT: 'Time it took (optional)',
  TIME_PER_PAGE: 'Time per page',
  PAGE_RANGE: 'Page range',
  ADD_A_PAGE_RANGE: 'Add page range',
  SUBTASK_NAME: 'Subtask name',
  ORDINAL_NUMBER_LABEL: '# (optional)',
  ORDINAL_NUMBER_PLACEHOLDER: 'If you have multiple',
  DUE_IN_CLASS_LABEL: 'Due in class @ ',
  CANCEL: 'Cancel',
  OK: 'Ok',
  MANAGE: 'Manage',
  LOOKS_LIKE_YOU_HAVENT_SET: "Looks like you haven't set",
  UP_YOUR_ACCOUNT_YET: 'up your account yet?',
  DONT_HAVE_THE_WEB_APP_SETUP_1: "Looks like you haven't set up",
  DONT_HAVE_THE_WEB_APP_SETUP_2: 'your account yet!',
  USING: 'Using',
  DONE: 'Done',
  UNPLAN: 'Unplan',
  PLAN: 'Plan',
  MOVE: 'Move',
  ARE_YOU_SURE_CONFIRMATION_MESSAGE: 'Are you sure? No changes will be saved.',
  TASK_RUNNING_TITLE: 'There is an active timer in another task.',
  OF_TOTAL_GRADE: 'of total grade',
  EACH: 'each',
  STUDY_TIME: 'Study Time',
  EXTRA_TIME: 'Extra Time',
  TIME_TO_COMMUTE: 'Time to commute',
  VIEW_WHEN_PLANNED: 'View when planned',
  VIEW_ALL: 'View all',
  VIEW_PAGES_READ: 'View pages read',
  PARTIAL_READINGS_TITLE: 'All Partial Readings',
  SELECT_TO_DO: 'To Do',
  SELECT_DO_LATER: 'Do Later',
  SELECT_COMPLETED: 'Completed',
  TODAY: 'Today',
  TOMORROW: 'Tomorrow',
  MOVE_TASK_TO_COMPLETED_HEADLINE: 'Move task to Completed?',
  MOVE_TASK_TO_DO_LATER_HEALDINE: 'Move task to Do Later?',
  MOVE_TASK_TO_TO_DO_HEALDINE: 'Move task to To Do?',
  MOVE_TASK_TO_DESCRIPTION: 'Since the task is complete, all future planned time related to this task will be freed!',
  MOVE_TASK_TO_TO_DO_DESCRIPTION:
    'You can adjust the due date & time since it has passed. Otherwise the task will be automatically moved to OVERDUE!',
  DO_NOT_SHOW_THIS_WARNING_AGAIN: 'Do not show me this warning again',
  ADD_MORE_TIME: 'Add more time',
  ADD_MORE_TIME_NEEDED: 'Add more time needed',
  REMOVE_PLANNED_TASK_CONFIRMATION_TITLE: 'Remove task from study block?',
  REMOVE: 'Remove',
  DELETE_FOREVER: 'Delete task',
  DELETE_FOREVER_WARNING: 'You will not be able to restore it!',
  MOVED_TO_TRASH: 'Moved to trash',
  DELETE_TASK: 'Move to trash',
  DELETE_COMMITMENT: 'Delete commitment',
  DELETE_COURSE: 'Delete course',
  DELETE_ACTIVITY: 'Delete activity',
  DELETE_EVENT: 'Delete event',
  DELETE_AWAKE_TIME: 'Delete awake time',
  DELETE_CATEGORY_ITEM: 'Delete category item',
  TOTAL_PAGES: 'Total pages',
  TOTAL_TIME: 'Total time',
  NEGATIVE_CUSHION: 'Negative Cushion',
  ADD_TO_PLANNER: 'Add to Planner',
  SAVE_CHANGES_MESSAGE: 'Save changes?',
  YES: 'Yes',
  NO: 'No',
  STUDY_BLOCK_PASSED: 'You can’t add task here. Study block has passed.',
  DUE_TIME_PASSED: 'Study block is after the task’s due date.',
  ADD_TASK_HERE: 'Add task here',
  DROP_HERE_TASK: 'Drop here to create a task',
  NO_COMMITMENT_TYPE_EMPTY_SCREEN:
    'No item in this category to choose from. Open Shovel on your computer and go to: Time Setup > Commitments.',
  NO_READING_SOURCES_EMPTY_SCREEN:
    'No reading source to choose from. To create a reading source, open Shovel on your computer and go to the course’ syllabus > reading sources.',
  NO_GRADE_UNITS_EMPTY_SCREEN:
    'No item in this category to choose from. Go to this course’ Grading Rubric on your computer.',
  ADD_PAGES_READ: 'Add pages read',
  ZERO_PAGES_READ: `It looks like you don't have any pages read.`,
  TASK_LIST_EMPTY_SEARCH_MESSAGE: 'No tasks match your search',
  PLANNER_EMPTY_MESSAGE: 'No study blocks this day.',
  READ_ALL: 'Read all',

  INVALID_CREDENTIALS: 'Incorrect username or password.',

  TASK: 'Task',
  TASKS: 'Tasks',
  AWAKE: 'Awake',
  COMMITMENTS: 'Commitments',
  SLEEP_TIME: 'Sleep time',

  ME_TIME_DESCRIPTION: 'AKA Friday, Saturday nights. Hungover mornings etc.',
  CONFIRM: 'Confirm',
  I_UNDERSTAND: 'I Understand',

  //Terms
  TERM: 'Term',
  SCHEDULE: 'Schedule',
  TUTORIAL: 'Tutorial',
  FIXED: 'Fixed - Classes occur on the same day every week',
  FIXED_INFO:
    'In the next step, you will create your weekly schedule that will be transfered to your calendar of every week of your semester.',
  WEEKLY_INFO:
    'In the next step, you will set up all your weeks in your weekly rotation which will be transferred into your calendar for the entire semester.',
  DAY_IRREGULAR: 'Irregular - Classes occur on random dates',
  DAY_IRREGULAR_INFO: 'Since your schedule is a bit more complicated, you’ll build it directly in our calendar.',
  SCHOOL_TIME_ZONE: 'School’s time zone',
  TERM_DATES: 'Term dates',
  END_OF_TERM: 'End of Term',
  SCHEDULE_TYPE: 'Schedule type',
  TERM_INFO: 'Term info (optional)',
  HOLIDAYS: 'Holidays',
  NO_OF_WEEKS: 'Number of weeks in rotation',
  START_WEEK: 'Start week',
  WEEKLY_INFO_PART1: 'Your next day of classes is ',
  WEEKLY_INFO_PART2: ' and it falls on ',
  WEEK: 'Week',
  WEEKS: 'Weeks',
  WEEKLY_INFO_PART3: ' in your weekly rotation.',
  IS_CORRECT: 'Is this correct?',
  YEP: 'Yep',
  COUNTRY: 'Country',
  CONTINUE: 'Continue',
  QUICK_TOUR: 'Quick Tour',
  WATCH_VIDEO: 'Watch this video before you get started!',
  ADD_HOLIDAY: 'Add a holiday',
  HOLIDAY_NAME: 'Holiday name',
  DATE_RANGE: 'Date range',
  DELETE_STUDY_TIME: 'I will study during this holiday.',
  CLASSES_START: 'Classes start',
  CLASSES_END: 'Classes end',
  END_EXAM: 'End of exam period',
  END_OF_CLASSES: 'End of classes',
  SCHOOL_NAME: 'School name',
  WHERE_GO_TO_SCHOOL: 'Where do you go to school?',
  WHAT_STUDYING: 'What are you studying?',
  YEAR: 'Year',
  EDUCATION_LEVEL: 'Education level',
  MAJOR: 'Major/concentration',
  SELECT: 'Select',
  SELECT_ALL: 'Select all',
  TERM_DATES_TOOLTIP: `You will not be able to set due dates for tasks that are outside your Classes start - End of exam period range. Classes will not repeat during your exam period.`,
  HOLIDAYS_TOOLTIP: 'Shovel will exclude classes from your calendar during holidays.',
  TERM_INFO_TOOLTIP: 'Keep track of your terms in Shovel.',
  HOLIDAY_NAME_PLACEHOLDER: 'Ex: Christmas',
  ENDS_ON_CUSTOM_DATE: 'Ends on custom date',

  EG: 'e.g.',
  NOTHING_PLANNED: 'Not planned in any study block.',
  STUDY: 'Study',
  PLAN_ME: 'Plan me',
  AVAILABLE: 'Available',
  EVENT: 'Event',
  DRAG_ME: 'Drag me',
  TIME: 'Time',
  ADD_A_NOTE: 'Add a note',
  DETAILS_PLACEHOLDER: 'Add details here...',
  SUBTASK_PLACEHOLDER: 'Type in subtask and press enter',
  EVENT_NAME: 'Event name',
  LOCATION: 'Location',
  BACK: 'Back',
  WARNING: 'Warning',
  GO_BACK_DIALOG_TEXT: `By going back, you’ll lose the details you’ve entered on this page.`,
  COMMUTE_BEFORE: 'Commute before',
  COMMUTE_AFTER: 'Commute after',
  UNPLAN_WHOLE_BLOCK: 'Unplan whole block',
  DETAILS: 'Details',
  FULLY_PLANNED: 'Fully planned',
  UPCOMING_TASKS_LABEL: 'Should be working on',
  PILE_UPCOMING_EMPTY_MESSAGE: 'All your upcoming tasks will be listed here.',
  TIMER_ACTIVE_MESSAGE: 'Timer active on a different task. Deal with that one first',
  HOLIDAY: 'Holiday',
  PASSED_PLANED_BLOCK_MESSAGE:
    'This study block has passed. Planning tasks inside this study block will not affect their planned time.',
  PILE_STATES_DESCRIPTION: `Should be working on<br />Tasks that are within their 'start xx days ahead' period which you set for each task. Make sure all these tasks are planned!<br /><br />To Do<br />All incomplete tasks with a future due date.<br /><br />Overdue<br />All tasks not manually marked as completed with a past due date.<br /><br />Completed<br />All tasks manually marked as completed.`,
  COMMUTE_TIME_PLACEHOLDER: 'i.e. 30min',
  NAME: 'name',
  EVENT_TYPE: 'Event type',
  ADD_EVENT_NAME: 'Add event name',
  ADD_DESCRIPTION: 'Add description',
  ADD_DETAILS: 'Add details',
  TYPE: 'Type',
  WORKLOAD_VIEW: 'Workload view',
  READINGS: 'Readings',
  TESTS: 'Tests',
  ASSIGNMENTS: 'Assignments',
  PARTICIPATIONS: 'Participation',
  EXTRA_TASKS: 'Ungraded',
  CHANGE_TERM: 'Change term',
  LOGOUT: 'Log out',
  ACCOUNT: 'Account',
  FROM: 'From',
  TO: 'To',
  UPDATE_APPLY_DIALOG_TITLE: 'Changing a repeating event',
  DELETE_APPLY_DIALOG_TITLE: 'Deleting a repeating event',
  THIS_EVENT: 'This event',
  THIS_AND_FOLLOWING: 'This and following',
  THIS_AND_ALL_FOLLOWING: 'This and all following',
  DOES_NOT_REPEAT: 'Does not repeat',
  REPEATS_EVERY: 'Every',

  //Field validation
  REQUIRED: 'Field is required',
  PASSWORDS_DONT_MATCH: "Passwords don't match",
  MIN_VALUE_ERROR: 'Min value is',
  MAX_VALUE_ERROR: 'Max value is',
  MAX_PAGES_READ_ERROR: 'Pages read sum cannot be greater than total pages',
  FROM_BIGGER_THAN_TO_ERROR: 'Must be greater than from',
  TODAY_DATE_ERROR: `Must be greater than today's date!`,
  END_CLASS_ERROR: 'Must be greater than start classes date!',
  EXAM_PERIOD_ERROR: 'Must be greater than end classes date!',
  TASK_WITHOUT_PAGE_RANGE_ERROR: 'Selected tasks must have at least 1 page range',
  END_MUST_BE_AFTER_START: 'Event must end after it starts',

  //Backend errors
  DATE_TIME_PASSED: 'Cannot set task due in the past',
  DATE_NOT_WITHIN_SEMESTER_RANGE: 'Date is not within semester range',
  DATE_NOT_WITHIN_SEQUENCE: 'Date not within sequence',
  CANNOT_DELETE_ACTIVE_SEMESTER: 'Cannot delete active term',
  END_BEFORE_START: 'End date cannot be before start date',

  //Emojies
  EMOJI_SURPRISED: '\\01F62F',
  EMOJI_WINKING: '\\01F609',
  EMOJI_BEACH: '🏖️',
  EMOJI_HOLIDAY: '⛱️',
  EMOJI_POINTING: '👉️',
  EMOJI_POINTING_LEFT: '👈',
  EMOJI_LIGHT_BULB: '💡',

  FIRST_NAME: 'First name',
  LAST_NAME: 'Last name',
  EMAIL: 'Email',
  PASSWORD: 'Password',
  WELCOME_TO_SHOVEL: 'Welcome to Shovel',
  ENTER_PASSWORD: 'Enter password',
  ENTER_EMAIL: 'Enter email',
  // MAX_LENGTH: 'It must be max {{number}} characters long',
  MAX_LENGTH: 'It must be max ',
  MIN_LENGTH: 'It must be min ',
  CHAR_LONG: 'characters long',

  TERM_USE: 'Term of use',
  CONTACT: 'Contact support',
  POLICY: 'Privacy policy',

  CHANGE_PASSWORD: 'Change password',
  SEND_REC_LINK: 'Send recovery link',
  RETURN_LOGIN: 'Return to Log in',
  FORGOT_PASSWORD_SUCCESS_TEXT: 'We sent a recovery link to your email address: ',
  CHECK_SPAM_FOLDER_MESSAGE:
    'Be sure to check your spam folder, too. Contact us via the chat on our website if you do not receive this email within 5 minutes.',
  SET_YOUR_PASSWORD: 'Set your password',
  CURRENT_PASSWORD: 'Current password',
  TYPE_CURRENT_PASSWORD: 'Type password',
  NEW_PASSWORD: 'New password',
  ENTER_NEW_PASSWORD: '8 or more characters',
  SUBMIT: 'Submit',
  PASSWORD_SET: 'Password Set',
  PASSWORD_SET_MESSAGE: 'You have successfully set your password',
  CONTINUE_TO_SHOVEL: 'Continue to Shovel',
  RESET_PASSWORD_EXPIRED: 'It looks like this password reset link can’t be used anymore.\n',

  // Subscription
  UPGRADE_TO_LIFETIME: 'Upgrade to lifetime membership',
  UPGRADE_TO_ANNUAL: 'Upgrade to annual subscription',
  CANCEL_SUBSCRIPTION: 'Cancel plan',
  CANCEL_SUBSCRIPTION_TEXT:
    'You’re about to cancel your subscription plan. You will have access to Shovel until the end of your next billing cycle. You will be able to come back to Shovel and resume/update your plan at any time. ',
  CHANGE_SUBSCRIPTION_CARD: 'Change subscription card',
  MEMBERSHIP: 'Membership',
  CURRENT_PLAN: 'Current plan',
  ACTIVE_UNTIL: 'active until',
  CANCELED: 'Canceled',
  FINISH: 'Finish',
  YOUR_PLAN: 'Your plan',
  MONTHLY: 'Monthly',
  NEXT_BILLING: 'Next billing',
  CURRENT_CARD: 'Current card',
  CARD_NUMBER: 'Card number',
  CARD_NUMBER_PLACEHOLDER: 'Add number',
  EXP: 'Exp',
  EXPIRATION_DATE: 'Expiration date',
  CVC: 'CVC',
  CHANGE_CARD: 'Change Card',
  CHANGE_YOUR_PLAN: 'Change your plan',
  UPGRADE_PLAN: 'Upgrade plan',
  DOWNGRADE_PLAN: 'Downgrade plan',
  YEARLY: 'Yearly',
  _6_MONTHS: '6 Months',
  LIFETIME: 'Lifetime',
  ACCESS: 'access',
  MONTH_PRICE: '$3,99',
  HALF_YEAR_PRICE: '$17,94',
  YEAR_PRICE: '$23,88',
  NO_PLAN_TEXT: '☝️Currently you are not on any plan!',
  SELECT_PLAN: 'SELECT PLAN',
  UNLIMITED_MONTH: (
    <span>
      Unlimited access to Shovel <br />
      for one month
    </span>
  ),
  UNLIMITED_HALF_YEAR: 'Unlimited access to Shovel for 6 months',
  UNLIMITED_YEAR: (
    <span>
      Unlimited access to Shovel
      <br /> for one year
    </span>
  ),
  UNLIMITED_LIFETIME: 'One-time payment for unlimited access to Shovel forever',
  OUR_MOST_POPULAR: 'OUR MOST POPULAR',
  FREE_WEB: 'Free web',

  // Credit card stripe validation errors
  'Your card number is invalid.': 'Your card number is invalid',
  'Your card number is incomplete.': 'Your card number is incomplete',
  "Your card's expiration date is incomplete.": `Date is incomplete`,
  "Your card's expiration year is in the past.": 'Date has passed',
  "Your card's expiration date is in the past.": 'Date has passed',
  "Your card's security code is incomplete.": 'Code is incomplete',

  SCHOOL_COUNTRY_PLACEHOLDER: 'Start typing...',
  SCHOOL_TIME_ZONE_PLACEHOLDER: 'Select in which time zone your assignments will be due',
  EMAIL_DOES_NOT_EXIST: 'Email does not exist',
  START_DATE_IS_REQUIRED: 'Start date is required',
  END_DATE_IS_REQUIRED: 'End date is required',
  CREATE_REPEAT_TASK: 'Create repeat task',
  CREATE_ONE_TASK: 'Create one task',
  DAYS_REPEAT_ON: 'What days does it repeat on?',
  REPEAT_READING_TASKS_TEXT: '👇️ Check dates, enter details and page range for each task',
  REPEAT_TASKS_TEXT: '👇️ Check dates and enter details for each task',
  NEXT: 'Next',
  CREATE: 'Create',
  CONFIRM_DATES: 'CONFIRM DATES',
  SELECTED: 'selected',
  SIGNUP_NAME_PLACEHOLDER: 'What do you want us to call you?',
  CREATE_ACCOUNT: 'Create account',
  SIGNUP_TITLE: 'Better grades. Less stress.',
  SIGNUP_FOR_SHOVEL: 'Sign Up for Shovel',
  ACTIVITIES: 'Activities',
  COURSES: 'Courses',
  CLASSES: 'Classes',

  COMFORTABLE_VIEW: 'Comfortable view',
  COMPACT_VIEW: 'Compact view',
  CALENDAR: 'Calendar',
  TASK_LIST: 'Task List',
  NO_DATA: `It looks like you don't have anything here.`,

  BOOKS: 'Books',
  BOOKS_TOOLTIP: `Add each book you need to read and it's estimated time per page.`,
  ARTICLE: 'Article',
  ARTICLE_TOOLTIP: 'Add each article type (Single Spaced, Double Spaced) and its estimated time per page.',
  TEST_TOOLTIP: `Add each type of test (Quiz, Midterm, Final) you'll be graded on.`,
  ASSIGNMENT_TOOLTIP: `Add each type of assignment (Problem Set, Paper, Project) you'll be graded on.`,
  UNGRADED_TOOLTIP: `Add each type of an ungraded task you want to do. (Lecture Review, Practice Exam, Paper Research)`,
  PARTICIPATION_TOOLTIP: `Add participation category and assign weight. Then enter your grade in the list on the right. `,
  ACTIVE: 'To Do',
  NO_STATUS: 'No status selected',
  NO_TYPE: 'No category selected',
  ARTICLE_TYPE: 'Article Type',
  ARTICLE_TYPES: 'Article Types',
  GRADED: 'Graded',
  BOOK: 'Book',
  AWAKE_TIME: 'Awake time',
  MONTH: 'Month',
  CALENDAR_ITEMS: 'Calendar items',
  TASK_CATEGORIES: 'Task categories',
  COURSE: 'Course',
  TIME_HAS_PASSED: 'Time has passed',
  EXTRA: 'Extra',
  UNPLAN_ALL: 'Unplan all',
  DRAG_AN_EVENT: 'Drag an event',
  DRAG_AWAKE_TIME: 'Drag awake time',
  EVENTS: 'Events',
  SET_MINIMUM_STUDY_TIME_BLOCK: 'Set Minimum Study Time Block',
  AWAKE_TIME_TEXT_1:
    'The dark areas represent your sleep time and Shovel only looks for your available study time during your awake time (white areas). Make sure to set this properly.',
  AWAKE_TIME_TEXT_2:
    'You can adjust your awake time by dragging and resizing each awake time or you can delete it and create a new one.',
  STUDY_TIME_TEXT:
    'Set the minimum amount of time you’ll need to get something done. Shovel will identify every time block that you can use for studying as Study Time.',

  GPA_CALC: 'GPA calculator',
  HELLO: 'Hello',
  VIEW_FILTERS: 'View filters',
  NA: 'N/A',

  CREATE_TEST: 'Create Test',
  CREATE_ASSIGNMENT: 'Create an Assignment',
  CREATE_UNGRADED: 'Create an Ungraded task',

  DELETE_COURSE_CONFIRMATION: 'Are you sure you want to delete this course',
  DELETE_ACTIVITY_CONFIRMATION: 'Are you sure you want to delete this activity',
  DELETE_EVENT_CONFIRMATION: 'Are you sure you want to delete this event',
  DELETE_AWAKE_CONFIRMATION: 'Are you sure you want to delete this awake time',
  DELETE_TASK_CONFIRMATION: 'Are you sure you want to delete task',
  DELETE_CLASS_CONFIRMATION: 'You are about to delete course',
  DELETE_CLASS_WARNING:
    'All events associated with this course will be deleted from your Calendar and all the tasks in this course will be deleted from the Pile as well.',
  DELETE_ACTIVITY_WARNING:
    'All events associated with this activity will be deleted from your Calendar and all the tasks in this activity will be deleted from the Pile as well.',
  DELETE_COMMITMENT_CONFIRMATION: 'You are about to delete commitment',
  DELETE_COMMITMENT_WARNING: 'This will delete all the events associated with it.',
  DELETE_CATEGORY_ITEM_CONFIRMATION: 'You are about to delete category item',
  DELETE_CATEGORY_ITEM_WARNING: 'This will delete all the tasks associated with it.',
  CREATE_EVENT: 'Create an event',
  CREATE_CLASS: 'Create a Class',
  DELETE_CLASS: 'Delete course',
  CREATE_ACTIVITY: 'Create an Activity',
  TEACHERS: 'Teachers',
  GRADES_CALCULATOR: 'Grades Calculator',
  GRADES_CALCULATOR_TEXT:
    'Enter weight for each category in the left sidebar. If you created a task in a category, we display it in the list below. To assign a weight to a task, we divide the category weight by the number of tasks in the given category. To enter grades for tasks from the past (tasks you did not create in Shovel), first create a category, then click ‘+ ADD GRADED ITEM.’',
  LETTER_GRADES: 'Letter Grades',
  LETTER_GRADES_TEXT:
    'If you receive a letter grade, enter your best possible estimate as a percentage (%) of what the letter grade represents based on your course’s grading system. Ask your professor if you need to.',
  CURVED_SCALE: 'Curved Scale',
  CURVED_SCALE_TEXT: 'If the grading scale is curved on a task, enter your grade in %.',
  OVERALL_GRADE_DISCLAIMER: 'Disclaimer',
  OVERALL_GRADE_DISCLAIMER_TEXT:
    'Your Overall Grade is an estimate for informational purposes only. Professors may adjust your grades based on the system they use.',
  ADD_GRADE: 'Add grade',
  DELETE_GRADE: 'Delete grade',
  GRADE: 'Grade',
  DATE: 'Date',
  DROP: 'Drop',
  CUSTOM_EVENTS: 'Custom Events',
  DRAG_A_CUSTOM_EVENTS: 'Drag a custom event',
  CUSTOM_EVENT: 'Custom Event',
  COURSE_PLACEHOLDER: 'Type in course name',
  ACTIVITY_PLACEHOLDER: 'Activity name',
  SYLLABUS_ITEM_PLACEHOLDER: 'Task category name',
  GRADED_CATEGORIES: 'Graded categories',
  PARTICIPATION: 'Participation',
  PERCENTAGE: 'Percentage',
  POINTS: 'Points',
  SETTINGS: 'Settings',
  NOTIFICATIONS: 'Notifications',
  PRICING_PLAN: 'Pricing Plan',
  ACTIVE_TERM: 'Active Term',
  SWITCH_TERM: 'Switch Term',
  EDIT_ACTIVE_TERM: 'EDIT YOUR ACTIVE TERM',
  CREATE_NEW_TERM: 'Create a New Term',
  CREATE_NEW_TERM_TEXT:
    'Seems like you want to create a new term. You won’t be able to use Shovel until the term setup is done. You can always come back to term settings and select the term you want to interact with. Let’s get you some A’s! ',
  SELECT_TERM_TEXT: 'SELECT WHAT TERM YOU WANT TO INTERACT WITH IN SHOVEL',
  DELETE_TERM: 'Delete Term',
  ABOUT_TO_DELETE_TERM: `You're about to delete term:`,
  LOOSE_DATA_FROM_TERM: `You will lose all your data from this term.`,
  TEACHERS_TEXT: 'Add who teaches this course. Enter office hours into details.',
  TEACHERS_NAME: "Teacher's name",
  TEACHERS_EMAIL: "Teacher's email",
  ADD_A_TEACHER: 'Add a teacher',
  TEACHER_NAME_PLACEHOLDER: 'Add name',
  TEACHER_EMAIL_PLACEHOLDER: 'Add email',
  TEACHER_DETAILS_PLACEHOLDER: 'Office hours e.g. Monday 11 - 12',
  RECORD_HOW_MANY_PAGES: `👇️ Record how many pages you've read so far`,
  PREVIOUS_TIME_PER_PAGE: 'Previous time per page estimate was',
  TODAY_TIME_PER_PAGE: `Today's time per page was`,
  USE_FOR_FUTURE_READINGS: 'Use this estimate for the future readings',
  ADD_TIME_SPENT: 'Add time spent',
  TIME_SPENT: 'Time spent',
  TRACK_PROGRESS: 'Track progress',
  TRACK_PROGRESS_TOOLTIP: 'Track task progress with built-in timer or enter time manually.',
  OVERDUE: 'Overdue',
  ACTIVE_TIMER: 'Another timer is already active',
  NO_ACTIVE_TIMER: 'No active timer',
  ACTIVE_TIMER_TOOLTIP: 'Active timer',
  DELETE_ACCOUNT: 'Delete Account',
  ABOUT_TO_DELETE_ACCOUNT: `You’re about to delete your Shovel account. You will no longer have access to Shovel through this account. All your data will be lost.`,
  SUBSCRIPTION_WILL_BE_CANCELLED: 'Your subscription will be cancelled.',
  ACCOUNT_SUCCESSFULLY_DELETED: 'Your account has been successfully deleted',
  LOGOUT_TITLE: 'Get back here soon and get to work!',
  LOGOUT_TEXT: `<div>
                  <i>
                      <div>We are what we repeatedly do.</div>
                      <div>Excellence then, is not an act, but a habit.</div>
                  </i>
                  <br />
                  <div>- Aristotle</div>
                </div>`,
  DATES: 'Dates',
  FORGOT_PASSWORD_SUCCESS_TITLE: 'Check your email',
  RESET_PASSWORD_EXPIRED_TITLE: 'Password reset link has expired',
  GO_TO_LOGIN: 'Go to log in',
  RESEND_ACTIVATION_LINK: 'Resend sign up email',
  RESET_ACTIVATION_LINK_SUCCESS_TITLE: 'Check your email',
  RESET_ACTIVATION_LINK_SUCCESS_TEXT: 'We sent an activation email to the address you specified.',
  RESEND: 'Resend',
  SWITCH_OFF_ONCE_YOU_ARE_DONE: 'Switch off once you are done',
  CUSHION_TITLE: 'Do you have enough time get everything done?',
  CUSHION_INFO_TEXT: `Time Available - Time Needed = The Cushion time for each task.\n\n If Cushion is ZERO, you have enough time to get a task done, but you cannot waste a single second.`,
  CUSHION_LEARN_MORE: 'Learn more about the cushion.',
  CUSHION_NO_DATA: 'The Cushion Graph will show all your To Do tasks once you create them.',
  REMAINING_TASKS: 'tasks remaining',
  REMAINING_TASK: 'task remaining',
  SHOW_ALL: 'Show all',
  DELETE_PAGE_RANGE: 'Reading task should have at least one page range',
  COMING_SOON: '️Coming soon',
  COMING_SOON_TEXT1: `Hi, sorry this page is still under construction. Don't worry, the data you had in the previous version is safe and we'll let you know when the`,
  COMING_SOON_TEXT2: 'is working again.',

  PASTEL: 'Pastel',
  BRIGHT: 'Bright',
  DARK: 'Dark',

  TERM_HINT_1_TITLE: 'Click for hints',
  TERM_HINT_1_TEXT: 'Discover every useful tip within the Shovel app.',
  // TERM_HINT_2_TITLE: 'Add holiday',
  // TERM_HINT_2_TEXT:
  //   "Holidays are optional, but we encourage you to set them up so that the calendar doesn't show classes during your holidays.",
  AWAKE_TIME_HINT_1_TITLE: 'Drag and drop awake time',
  AWAKE_TIME_HINT_1_TEXT: 'Create awake time for every day.',
  AWAKE_TIME_HINT_2_TITLE: 'Switch of when done',
  AWAKE_TIME_HINT_2_TEXT: '',
  EVENT_REPEATING_OPTIONS_HINT_1_TEXT: 'Discover repeating options',
  EVENT_REPEATING_OPTIONS_HINT_1_TITLE:
    'This will speed up your scheduling  and will be applied to every future day within the calendar.',
  // SCHEDULE_HINT_1_TITLE: 'Set your awake time',
  // SCHEDULE_HINT_1_TEXT: 'You can’t study when you’re asleep. Let Shovel know when to look for your study time.',
  SCHEDULE_HINT_1_TITLE: 'Create a course',
  SCHEDULE_HINT_1_TEXT: 'Click ‘+’ to create a course.',
  SCHEDULE_HINT_2_TITLE: 'Drag and drop',
  SCHEDULE_HINT_2_TEXT: 'Schedule your activities and custom events.',
  SCHEDULE_HINT_3_TITLE: 'Set your study time',
  SCHEDULE_HINT_3_TEXT: '',
  STUDY_TIME_HINT_1_TITLE: 'Set your study time',
  STUDY_TIME_HINT_1_TEXT: 'Set the minimum amount of time you’ll need to get something done.',
  COURSE_HINT_1_TITLE: 'Edit or drag and drop into calendar',
  COURSE_HINT_1_TEXT:
    'Now you’re in the course. \n\n Enter a short course name, pick a color. \n\n Drag and drop course into the calendar to schedule a class',
  COURSE_HINT_2_TITLE: 'Create task sub-categories and drag them to create a task',
  COURSE_HINT_2_TEXT: '',
  COURSE_HINT_3_TITLE: 'Manage your course content',
  COURSE_HINT_3_TEXT: 'Creating and organizing schedule, tasks, grades and teachers',
  TASK_LIST_VIEW_HINT_1_TITLE: 'Drag and drop',
  TASK_LIST_VIEW_HINT_1_TEXT: 'Yup, now it’s possible! Feel free to drag and drop it to the table.',
  TASK_DIALOG_HINT_1_TITLE: 'Does it repeat or not?',
  TASK_DIALOG_HINT_1_TEXT: 'Try these options for creating single or multiple tasks at a time.',
  PLANNER_NAVIGATION_HINT_1_TITLE: 'Plan a task',
  PLANNER_NAVIGATION_HINT_1_TEXT: 'Plan when you’ll get each task done.',
  PLANNER_HINT_1_TITLE: 'Drag and drop to plan',
  PLANNER_HINT_1_TEXT: 'When and for how long you will work on task.',
  PLANNER_DIALOG_HINT_1_TITLE: 'How long do you want to spend on this task during this study time?',
  PLANNER_DIALOG_HINT_1_TEXT: 'Shovel pre-filled to the time you need.',
  PLANNER_BAR_HINT_1_TITLE: 'Click here to plan your tasks',
  GOT_IT: 'Got it',
  MONTHS: 'Months',
  SEMI_ANNUAL: 'Semi-annual',
  UPGRADE_SUBSCRIPTION: 'Upgrade Your Plan',
  UPGRADE_SUBSCRIPTION_TEXT:
    "If there is time left on your current plan, we will subtract the value of what's left from the price of your new plan. ",
  DOWNGRADE_SUBSCRIPTION: 'Downgrade Your Plan',
  DOWNGRADE_SUBSCRIPTION_TEXT: 'Once your current subscription period runs out, your new subscription plan will kick in.',
  ACCESS_UNTIL: 'Access until',
  NEGATIVE_CUSHION_FILTER_TOOLTIP: 'Cushion is applicable only in All To Do and Within start date sections',
  CARD_HIDDEN_DIGITS: '**** **** ****',
  OTHER: 'Other',
  BACK_TO_SCHEDULE: 'Back to Schedule',
  SAVING: 'Saving...',
  SAVED: 'Saved',
  FAILED: 'Failed',
  REACTIVATE: 'Reactivate',
  REACTIVATE_SUBSCRIPTION: 'Reactivate plan',
  REACTIVATE_SUBSCRIPTION_TEXT:
    'You’re about to reactivate your subscription plan. Once your billing cycle ends, it will be re-newed.',
  SET_TERM: 'Set Term',
  SET_AWAKE_TIME: 'Set awake time',
  SET_SCHEDULE: 'Set schedule',
  SET_STUDY_TIME: 'Set study time',
  CREATE_TASKS: 'Create tasks',
  PLAN_TASKS: 'Plan tasks',
  PLAN_TASK: 'Plan task',
  SETTINGS_PAGE: 'Settings page',
  GPA_CALCULATOR: 'GPA Calculator',
  ADD_GRADES: 'Add grades',
  OVERVIEW_VIDEO: 'Overview video',
  WHATS_NEW: 'What’s new?',
  TERM_GPA: 'Term GPA',
  TERM_CREDIT: 'Term Credit',
  CREDIT: 'Credit',
  ADD_TERM_NAME: 'Add term name',
  TERM_GPA_TOOLTIP: 'Whatever',
  TERM_CREDIT_TOOLTIP: 'Whatever',
  DELETE_TERM_GPA_TITLE: 'Delete term GPA',
  DELETE_TERM_GPA_TEXT_1: 'Are you sure you want to delete term',
  DELETE_TERM_GPA_TEXT_2: 'from your GPA calculation?',
  ADD_COURSE_GPA_TITLE: 'Note',
  ADD_COURSE_GPA_TEXT: 'Please note that adding course will remove your Term GPA and Term Credit options.',
  ADD_A_COURSE: 'ADD A COURSE',
  ADD_COURSE_NAME: 'Add course name',
  SCALE: 'Scale',
  GPA_SCALE_TOOLTIP:
    'Choose the scale that your school uses:\n\n4.0 Scale: An A+ has the same value as an A.\n4.3 Scale: An A+ has a higher value than an A.',
  GPA_SIDEBAR_TEXT: 'Calculate your GPA and stay on top of your study grades.',
  CUMULATIVE_GPA: 'CUMULATIVE GPA',
  ADD_A_TERM: 'ADD A TERM',
  PAGE_RANGE_ERROR: 'Incorrect page range',
  FIRST_STOP_TIMER: 'Timer should be stopped first',
  CLASSES_DURING_HOLIDAYS_WARNING_1: 'You are trying to create a course in',
  CLASSES_DURING_HOLIDAYS_WARNING_2: 'Classes cannot start during holidays.',
  REPEAT_CLASSES_DURING_HOLIDAYS_NOTE: 'Note: Classes created with repeat options will not repeat during holidays.',
  CUSHION_GREEN_BULLET: 'You have more time than you need.',
  CUSHION_YELLOW_BULLET: 'You are starting to run out of extra study time.',
  CUSHION_RED_BULLET: `You don't have enough time to get the task done. Adjust your schedule.`,
  CUSHION_INPUT_TEXT: 'Turn my Cushion yellow when the Cushion of a task = 40% of the time needed to complete the task.',
  CUSHION_INPUT_TOOLTIP: `Example when set to 40%:<br /><br />Time needed to complete a task = 10 hours. 40% of 10 hours = 4 hours.<br />When Cushion is 4 hours or less, it turns yellow`,
  EVENT_AWAKE_TIME_WARNING_TEXT: 'This event overlaps with your sleep time.',
  PLANNED_STUDY_AWAKE_TIME_WARNING_TEXT:
    'Planned study time overlaps with your sleep time. This will not affect your available study time.',
  EVENT_PLANNED_STUDY_WARNING_TEXT:
    'Planned study time overlaps with another event or holiday. This will not affect your available study time.',
  NOT_FOUND_TITLE: 'Ooops! Error 404',
  NOT_FOUND_TEXT: `<div>
    <p>Looks like Bandit here ate this page. Just like he did with your homework in 5th grade.</p>
    <p>Go back to <a href='/'>dig.shovelapp.io</a></p>
    </div>`,
  LEFT: 'left',
  BROWSER_NOT_SUPPORTED: 'Browser is not supported',
  BROWSER_NOT_SUPPORTED_TEXT: `You are trying to use Shovel web-app in an unsupported browser. To use our app please use popular browsers like Chrome, Firefox, Safari or Opera.`,
  MAINTENANCE_TITLE: 'Shovel is under maintenance',
  MAINTENANCE_TEXT: `<div>
    <p>Our web-app is currently undergoing scheduled maintenance, so come back in an hour.</p>
    <p>Sorry for the inconvenience and thank you for your patience.</p>
    <p>To learn more about Shovel, go to: <a href='https://howtostudyincollege.com/shovel-study-planner'>howtostudyincollege.com/shovel-study-planner</a></p>
    </div>`,
  GET_SHOVEL: `Get Shovel`,
  AWAKE_TIME_LONGER_THAN_A_DAY: 'Awake time per day cannot exceed 24 hours',
  CLASS_LONGER_THAN_A_DAY: 'Classes cannot exceed 24 hours',
  ACTIVITY_LONGER_THAN_A_DAY: 'Activities cannot exceed 24 hours',
  TASKS_HEADER_GRADED_TOOLTIP: 'Due tests and assignments',
  TASKS_HEADER_OTHER_TOOLTIP: 'Due reading and ungraded tasks',
  VIEW: 'View',
  VIEW_TIME_SPENT: 'View time spent',
  VIEW_TIME_SPENT_SUBTITLE: `👇️ View how much time you've worked so far`,
  NO_TIME_ENTRY: `It looks like you don't have anything here`,
  // backend errors

  'upgrade.subscription.invalid.request': 'Invalid upgrade subscription request',
  'resend.signup.link.account.already.created': 'Account with this email is already created',
  'resend.signup.link.invalid.mail': 'There is no payment associated with this email',
  'signup.invalid.code': 'Invalid signup code',
  'signup.used.code': 'Signup code already used',
  'signup.expired.code': 'Signup code has expired. Go to howtostudyincollege.com and subscribe again',
  'reactivate.subscription.already.active': 'Subscription already active',
  'cancel.subscription.already.canceled': 'Subscription already canceled',
  'subscription.expired': 'Your subscription has expired. To continue using Shovel, contact us in chat.',
  'reset.password.invalid.code': 'Invalid reset password code',
  'invalid.password': 'Invalid current password',
  'passwords.equal': 'Passwords are equal',
  'invalid.stripe.coupon': 'Invalid coupon',
  'invalid.braintree.coupon': 'Invalid coupon',
  'email.verified': 'Email is already verified',
  'emails.equal': "You didn't change the email",
  'email.already.exists': 'Email is already used',
  // todo: check can we assume these scenarios here
  'invalid.email.verification.code': 'Email is already verified',
  'invalid.reset.trial.code': 'Trial is already reset',
  'create.subscription.on.braintree.failed': 'Payment failed. Please check your payment method and try again.',
  'redeem.access.invalid.code': 'Code is either invalid or you have already redeemed access',
  'user.account.not.activated': 'Account not activated yet. Check your email inbox.',

  ACCOUNT_ALREADY_ACTIVATED: 'Account is already activated',

  CREATE_READINGS_WITH_MISSING_RANGES: 'Tasks with missing page ranges will not be created',
  DUPLICATE: 'Duplicate',
  SHOW_COMPLETED: 'Show completed',
  HIDE_COMPLETED: 'Hide completed',
  NAVIGATING_SHOVEL: 'Navigating Shovel',
  CALENDAR_VIEW_TOOLTIP: 'Switching calendar view is not allowed in awake time and study time mode',
  SELECT_STUDY_BLOCK: 'Select a study block ',
  CHAT_WITH_US: 'Chat with us',
  HELP_WITH_FEATURES: 'Help with features',
  PRICING: 'Pricing',
  CLEAR_ALL: 'Clear all',
  RESET_WALKTHROUGH_HINTS: 'Reset walkthrough hints',
  SKIP_ONBOARDING: 'Skip Hints',
  TYPICAL_DAY_START: 'My typical day starts at ',
  TIME_FORMAT: 'Time format',
  DATE_FORMAT: 'Date format',
  START_WEEK_ON: 'Start week on',
  MONDAY: 'Monday',
  TUESDAY: 'Tuesday',
  WEDNESDAY: 'Wednesday',
  THURSDAY: 'Thursday',
  FRIDAY: 'Friday',
  SATURDAY: 'Saturday',
  SUNDAY: 'Sunday',
  PERSONALIZATION: 'Personalization',
  SEE_ALL_NOTIFICATIONS: 'See all notifications',
  NOTIFICATION_EMPTY_MESSAGE: `You’re all caught up!<br/>
    Come back later for reminders,
    study tips and plan notifications.`,
  UNSYNC_GOOGLE_CALENDAR_TITLE: 'Delete Calendar',
  UNSYNC_GOOGLE_CALENDAR_MESSAGE1: 'You’re about to remove all imported',
  UNSYNC_GOOGLE_CALENDAR_MESSAGE2: 'events from this app. Are you sure you want to continue?',
  CONNECTED_PLATFORMS: 'connected platforms',
  CONNECTED_CALENDARS: 'Connected Calendars',
  CALENDARS: 'Calendars',
  SYNC_CALENDAR_LIST: `If you've made changes to any of your calendars outside Shovel, sync them to get all the changes`,
  DELETE_AND_UNSYNC: 'Delete and unsync',
  GOOGLE_SYNC_BUTTON_TOOLTIP:
    'You can have only one synced Google Calendar. Delete the current calendar to sync with a new one.',
  CREATED_BY: 'Created by',
  AT: 'At',
  FREE_GOOGLE_EVENT_TOOLTIP: 'This event is marked as “Free” in Google Calendar and does not take up Study Time.',
  BUSY_GOOGLE_EVENT_TOOLTIP: 'This event is marked as “Busy” in Google Calendar and takes up Study Time.',
  TAKES_UP_STUDY_TIME: 'Takes up study time',
  DOESNT_TAKE_UP_STUDY_TIME: `Doesn't take up study time`,
  GOOGLE_EVENTS: `Google Events`,
  AGENDA: 'Agenda',
  DRAG_AND_DROP: 'Drag & Drop Tasks Here',
  COMMUTE_AGENDA: 'Commute',
  SCHEDULE_EVENT: 'Schedule an event',
  GOOGLE_EVENT_TOOLTIP: platform => `Events imported from ${platform} cannot be edited inside shovel app`,

  HELP_US: 'Help us improve',
  DELETE_ACCOUNT_MSG: 'Why do you want to permanently delete your Shovel account?',
  CANCEL_PRICING_PLAN_MSG: 'Why do you want to permanently cancel your pricing plan?',
  DESCRIBE_EXPERIENCE: 'Describe your experience',
  DELETING_REASON1: `Don't like Shovel`,
  DELETING_REASON2: `Can't find things`,
  DELETING_REASON3: 'Difficult to learn',
  DELETING_REASON4: 'Too hard to use',
  DELETING_REASON5: 'Too slow to load',
  DELETING_REASON6: 'Missing feature',
  DELETING_REASON7: 'Too expensive',
  DELETING_REASON8: 'Other',
  INCLUDE_PLANNED_IN_CUSHION: 'Include planned time in cushion.',
  INCLUDE_PLANNED_IN_CUSHION_TOOLTIP:
    'If you plan a task into a study block, that study time will be eliminated from available study time for other tasks, reducing their cushion, just like when you schedule an event.',
  ALL_DAY: 'All day',
  E_BOOK_TEXT: 'eBook: Perfect Study Plan',
  BUSY: 'Busy',
  ADD_COURSE: 'Add course',
  ADD_COURSE_FROM: 'Add course from',
  ADD_ACTIVITY: 'Add activity',
  SYNC_GOOGLE_CALENDAR: 'Sync Google calendar',
  CONNECT_GOOGLE_CALENDAR: 'Connect your Google Calendar. Will not work in incognito mode!',
  BOOK_PLACEHOLDER: 'Reading source',
  ARTICLE_TYPE_PLACEHOLDER: 'Article type name',
  TEST_PLACEHOLDER: 'Test name',
  ASSIGNMENT_PLACEHOLDER: 'Assignment name',
  UNGRADED_PLACEHOLDER: 'Name',
  PARTICIPATION_PLACEHOLDER: 'Participation name',
  DRAG_AND_DROP_CATEGORY: 'No tasks yet? Drag & Drop categories to create tasks',
  SET_BROWSER_NOTIFICATIONS: 'Set browser notifications',
  TASKS_YOU_SHOULD_START_WORKING_ON: 'Tasks you should start working on',
  SELECT_A_COURSE: 'Select course',
  SELECT_TASK_CATEGORY: 'Select task category',
  READING_SOURCES: 'Reading sources',
  NO_COURSES_TO_SELECT: 'There are no courses to select. Create one first',
  NO_ACTIVITIES_TO_SELECT: 'There are no activities to select. Create one first',
  DARK_MODE_TOOLTIP: 'Toggle dark mode',
  NOTIFICATIONS_TOOLTIP: 'View notifications',
  COLOR_PICKER_TOOLTIP: 'Choose color',
  PREVIOUS: 'Previous',
  CALENDAR_SLIDER_TOOLTIP: 'Adjust calendar time slot size',
  EXPAND: 'Expand',
  COLLAPSE: 'Collapse',
  SIDEBAR: 'sidebar',
  LOGO_TOOLTIP_TEXT: 'SHOVEL - Makes your life easier',
  ADJUST: 'Adjust',
  LIGHT_MODE: 'Light Mode',
  DARK_MODE: 'Dark Mode',
  ACCESSIBILITY: 'Accessibility',
  MORE: 'More',
  BADGE: 'Badge',
  BADGES: 'Badges',
  HOW_TO_COLLECT_IT: 'HOW TO COLLECT IT',
  PRODUCTIVITY_BADGES: 'PRODUCTIVITY BADGES',
  BADGES_PAGE_INFO: 'Productivity badges encourage the students to complete all the steps within the Shovel App. ',
  OVERVIEW_VIDEO_TITLE: 'Learn how to earn extra days of free trial',
  ALL_BADGES_ACHIEVED_TITLE: 'All badges achieved!',
  ALL_BADGES_ACHIEVED_TEXT: 'Congratulations. You’ve achieved all badges!',
  ALL_BADGES_ACHIEVED_ADDITIONAL_TEXT: 'You’re ready to crush the semester.',
  BADGES_INTRO:
    'You are on your way to earn your first badge. With each badge you unlock, you’ll get an extra day of free trial.',
  BADGES_PAGE_LINK: 'GO TO BADGE’S PAGE',
  SHARE_WITH_OTHERS: 'SHARE WITH OTHERS',
  EARN_FREE_TRIAL_DAY: '👉 You just earned an extra day of free trial!',
  CONGRATULATIONS: 'Congratulations!',
  BADGE_UNLOCKED: 'badge unlocked',
  ROCKSTAR_ROOKIE: 'Rockstar Rookie',
  SCHEDULE_ALCHEMIST: 'Schedule Alchemist',
  TASK_NINJA: 'Task Ninja',
  GOD_OF_TIME: 'The God of Time',
  PLANNING_GRADUATE: 'Planning Graduate',
  ACE_OF_INITIATIVE: 'Ace of Initiative',
  PRECISION_UNICORN: 'Precision Unicorn',
  ROCKSTAR_ROOKIE_QUOTE: 'Set your goals high, and don’t stop till you get there. All the best.',
  SCHEDULE_ALCHEMIST_QUOTE: 'Nothing can stop you if you keep working this hard.',
  TASK_NINJA_QUOTE: 'Success comes only to those who believe in themselves and are prepared to win. Good Luck.',
  GOD_OF_TIME_QUOTE: 'Do not wait until the conditions are perfect to begin. Beginning makes the conditions perfect.',
  PLANNING_GRADUATE_QUOTE: 'You have worked incredibly hard. Good luck. We are proud of you.',
  ACE_OF_INITIATIVE_QUOTE: 'Good luck comes to those who work hard.',
  PRECISION_UNICORN_QUOTE: 'Set your goals high, and don’t stop till you get there. All the best.',
  AND: 'and',
  BADGE_TOOLTIP: `Complete \${steps} to earn \${name} badge`,
  BADGE_TOOLTIP_EXTRA_DAY: ` and an extra day of free trial.`,
  COMPLETE: 'Complete',
  STEP: 'step',
  STEPS: 'steps',
  OF: 'of',
  WATCH_WHY_AND_HOW_QUIZ: '👇 Watch this video before answering the question below',
  VIDEO: 'Video',
  FAQ: 'FAQ',
  DESCRIPTION: 'Description',
  NEED_HELP: 'NEED HELP?',
  TO_COMPLETE_STEP: 'To complete this step:',
  QUICK_SETUP_STEP_TITLE: 'Complete quick setup',
  QUICK_SETUP_STEP_DESCRIPTION: `Finish the quick setup:
  • Set your school’s timezone
  • Set your term dates
  • Set when you usually waking up and go to bed
  • Add your courses
  • Add your other commitments and activities`,
  SCHEDULE_STEP_TITLE: 'Create your schedule',
  SCHEDULE_STEP_CLASS: `Drag a course from sidebar into your calendar to schedule a class.`,
  SCHEDULE_STEP_ACTIVITY: `Schedule at least one activity.`,
  TIME_NEEDED_STEP_TITLE: 'Estimate your tasks (Advanced)',
  TIME_NEEDED_STEP_DESCRIPTION: `Answer the question below`,
  TIME_NEEDED_STEP_QUIZ: 'Finish the sentence below to complete this step.',
  TIME_NEEDED_STEP_QUIZ_QUESTION: `Shovel knows the "Task Estimate" for a task with a READING SOURCE by:`,

  // Time needed possible answers
  GUESS_TIME_NEED: 'Letting you estimate how much time you need for the task.',
  MULTIPLY_TIME_PER_PAGE: `Multiplying the reading source's time per page by the number of pages you need to read.`,

  START_AHEAD_STEP_TITLE: 'Learn about setting Start Date (Advanced)',
  START_AHEAD_STEP_DESCRIPTION: `Answer the question below`,
  START_AHEAD_STEP_QUIZ_QUESTION: 'The study time available for a task is calculated:',

  // Start ahead possible answers
  SEMESTER_START: 'From the beginning of the semester until the task’s due date',
  TASK_START: 'From the task’s start date until its due date',

  CREATE_TASK_STEP_TITLE: 'Create your tasks',
  CREATE_TASK_STEP_CATEGORY: `Open a course and create a task category.`,
  CREATE_TASK_STEP_TASK: `Create at least one task.`,
  PLAN_TASK_STEP_TITLE: 'Plan your tasks',
  PLAN_TASK_STEP_DESCRIPTION: `Plan one task into a specific study time.`,
  TRACK_PROGRESS_STEP_TITLE: 'Track progress and complete a task (Advanced)',
  TRACK_PROGRESS_STEP_PROGRESS: `Mark a planned time as used`,
  TRACK_PROGRESS_STEP_COMPLETE: `Complete one task`,
  CUSHION_STEP_TITLE: 'Understand your Cushion (Advanced)',
  CUSHION_STEP_QUIZ: 'Check all that’s true about the Cushion',

  // Cushion quiz possible answers
  TIME_TO_WASTE: 'Cushion represents the Study Time I can waste',
  CALCULATE_BETWEEN: 'Cushion for a task is calculated only between the start date and due date of a task',
  TASK_CUSHION_NEGATIVE: 'When Cushion for a task is NEGATIVE, it means I have enough study time to get the task done',
  INCREASE_WITH_START_DATE: 'I can increase Cushion for a task by setting an earlier Start date',
  INCREASE_BY_DELETING: 'I can increase Cushion by deleting activities from my calendar',

  STUDY_TIME_STEP_TITLE: 'Adjust your study time (Advanced)',
  STUDY_TIME_STEP_DESCRIPTION: `Adjust your 'Minimum Study Time Block' slider to see how it changes your available study time. (Find Study Time settings in the "more menu" in bottom left.)`,
  AWAKE_TIME_STEP_TITLE: 'Adjust your awake time (Advanced)',
  AWAKE_TIME_STEP_DESCRIPTION: `Change Awake Time for at least one day. (Find Awake Time settings in "more menu" in bottom left or by hovering Sleep time of each day.) `,
  TIMEZONE: 'Timezone',
  TIMEZONES: 'Timezones',
  // TIMEZONES_SETUP_DESCRIPTION: `Not on campus this semester? Your SCHOOL timezone may be different than your HOME timezone. Enter both for easy
  //         task creation and event scheduling.`,
  TIMEZONES_SETUP_DESCRIPTION: `Enter your school’s timezone to make sure your assignments are due at the right time.\n\nIf you’re studying from home this term, set this to your Home Timezone but make sure you adjust your deadlines.`,
  TERM_DATES_SETUP_DESCRIPTION: `Enter when your term ends. A term is your semester, quarter, trimester etc. `,
  SEMESTER_START_TITLE: 'When do/did your classes start?',
  CLASSES_END_TITLE: 'When will your classes end?',
  EXAM_PERIOD_END_TITLE: 'When will your exam period end?',
  EXAM_PERIOD_END_TOOLTIP:
    'Exam period is typically between your last class and your last exam. Shovel will exclude class times during exam period.',
  HOLIDAYS_SETUP_DESCRIPTION: `Shovel will exclude classes from your calendar during holidays.`,
  AWAKE_TIME_SETUP_DESCRIPTION: `You can’t study when you’re asleep. Enter when you’re awake.`,
  WAKE_UP_TITLE: 'What time do you usually wake up?',
  WAKE_UP: 'Wake up',
  BEDTIME_TITLE: 'What time do you usually go to bed?',
  BEDTIME: 'Bedtime',
  ACTIVITY_SETUP_DESCRIPTION: `What regular activities will you be doing this term? You can always adjust them later on.`,
  GCAL_SETUP_DESCRIPTION: `If you already have events in your Google Calendar connect it to Shovel so you don't duplicate your schedule.\n\n If you have calendar files or use Apple calendar, for now, sync those with your Google Calendar and connect Shovel to your Google Calendar.`,
  ENTER_SHORT_COURSE_NAME: 'Enter short course name',
  ENTER_SHORT_ACTIVITY_NAME: 'Enter short activity name',
  ADD_ANOTHER: 'Add another',
  CURIOSITY_TITLE: 'Curiosity Question',
  CURIOSITY_QUESTION: 'Hey, do you think you are an organized student?',
  SAVE_STEP_CHANGES: `Do you want to save changes you made on this step?`,
  QUICK_SETUP_NEWBY_TEXT:
    'I’m Shovey, I’ll guide you through a few steps to get you digging for perfect grades as quickly as possible.',
  QUICK_SETUP_ADVANCED_TEXT: 'Another pile of homework? Let’s get it done!',
  TERM_EXPIRED_TEXT: 'Welcome back! Got a new pile of homework? I’ll help you get it done.',
  CHART: 'Chart',
  TIMELINE: 'Timeline',
  TIMELINE_NO_DATA: 'The Cushion Timeline will show all your To Do tasks once you create them.',
  FILTER_COLOR: 'Filter color',
  COLOR_BY: 'Color by',
  QUICK_TIP: 'Quick Tip: Pinch in and out to zoom your timeline',
  DEFAULT: 'Default',
  RENAME: 'Rename',
  CHANGE_COLOR: 'Change color',
  TASK_CATEGORY_DRAG_TOOLTIP: 'Drag to the right to create a task',
  DISCOVER_SHOVEL: 'Discover Shovel',
  PICK_COLOR: 'Pick color',
  VIEW_EVERYTHING: 'View Everything',
  TASK_CATEGORIES_TITLE: 'Task Categories',
  ADD_TASK_CATEGORY: 'Add task category',
  START_A_NEW_TERM: 'START A NEW TERM',
  GO_TO_MY_OLD_TERM: 'GO TO MY OLD TERM',
  CHANGE_TIME_PER_PAGE: 'Time per page',
  COURSE_SETTINGS: 'Course settings',
  EDIT_ACTIVITY: 'Edit activity',
  EDIT_TASK_CATEGORY: 'Edit task category',
  BADGES_LOCKED_MESSAGE: 'Create new term to unlock badges',
  MY_PILE_TAB: 'My Pile',
  PLAN_TASKS_TAB: 'Plan Tasks',
  ONBOARDING_TOUR: 'Onboarding Tour',
  DELETE_ACCOUNT_LABEL: 'Delete account',
  SUBSCRIBE: 'Subscribe',
  START_SUBSCRIPTION: 'Start new subscription',
  START_SUBSCRIPTION_TEXT: 'You’re about to start new subscription',
  SUBSCRIPTION_PLANS: 'Subscription plans',
  EXPIRED_SUBSCRIPTION: 'Expired Subscription',
  EXPIRED_SUBSCRIPTION_TEXT_FAILED_PAYMENT:
    'Contact support after making sure that you have enough funds and your card has not expired.',
  EXPIRED_SUBSCRIPTION_TEXT_CANCELED:
    'You either canceled your subscription or the last payment on your subscription failed. Please select a plan, payment method, and subscribe again. If you need assistance, contact support in chat.',
  EXPIRED_SUBSCRIPTION_TEXT_INITIAL_PAYMENT_FAILED:
    'We’re sorry, but your trial has expired since the initial payment on the subscription failed. Please select a plan, payment method, and try again. If you need assistance, contact support in chat.',
  TASKS_CREATED: 'tasks created',
  TASK_CREATED: 'task created',
  TASK_CATEGORY: 'Task category',
  MORE_OPTIONS: 'More options',
  PLAN_THIS_BLOCK: 'Plan this block',
  CONVERT_TO_ME_TIME: `Convert to "Me Time"`,
  ADD_STUDY_BREAKS: 'Add study breaks',
  REPEATING_OPTIONS: 'Repeating options',
  EMPTY_MESSAGE_PLAN_BLOCK: 'No tasks found',
  EMPTY_COURSE_PLACEHOLDER: 'To create a task, you need to create a task category first',
  SAVE_PLANET: '🌳 Save the planet.\n Think before printing.',
  SIGN_UP_HERE: 'Sign up here',
  VERIFY_EMAIL_MESSAGE: 'Verify your email. Check your inbox ',
  EMAIL_VERIFICATION_LINK_RESENT: 'Email verification link is resent',
  VERIFY_EMAIL_SUCCESS: 'Email verified',
  UPDATE_EMAIL: 'Update email',
  CONFIRM_BY_ENTERING_PASSWORD: 'Confirm by entering password',
  INVALID_EMAIL_FORMAT: 'Invalid email format',
  CHANGE_EMAIL: 'Change email',
  CHANGE_FIRST_NAME: 'Change first name',
  MANAGE_BILLING: 'Manage Billing',
  EMPTY_TASK_CATEGORIES: 'Your course does not have any task categories. Create them in the sidebar first',
  SIGNUP_SUBTITLE: 'Give your brain a break',
  // SIGNUP_TRIAL_TEXT: '14-day free trial - No credit card required',
  // START_FREE_TRIAL: 'Start your 14-day free trial',
  ALREADY_HAVE_ACCOUNT: 'Already have an account?',
  SHOW_TODO_TASKS_LIMIT_MESSAGE:
    '⚠️ We only load up to 300 tasks at a time. More tasks will show as their due date approaches.',
  SIGNUP_EMAIL_PLACEHOLDER: 'What is your email?',
  SIGNUP_PASSWORD_PLACEHOLDER: 'Set your password',
  SIGNUP_BUTTON_TITLE: 'Get started with Shovel',
  GO_BACK: 'Go back',
  SELECT_TASKS_TO_PLAN: 'Select tasks to plan',
  PLAN_TIME_FOR_TASK: 'Plan time for each task',
  EXPORT_SYLLABUS: 'Export syllabus',
  IMPORT_SYLLABUS: 'Import syllabus',
  GROUP_TASKS_BY: 'Group by',
  DUE_DATE: 'Due Date',
  TASK_TYPE: 'Task Type',
  ALL: 'All',
  NONE: 'None',
  VIEW_SETTINGS: 'View settings',
  HIDE_COLUMNS: 'Hide columns',
  PUSH_DUE_DATE: 'Push due date',
  PUSH_DUE_DATE_BY_DAYS: 'Push due date by days',
  PUSH_DUE_DATE_TOOLTIP: 'Positive number for later due date. Negative number for earlier due date.',
  OPEN: 'Open',
  WILL_BE: 'will be',
  DELETED: 'deleted',
  MARKED_COMPLETED: 'marked as completed',
  MARKED_TODO: 'marked as to do',
  SET_TIME_NEEDED: 'Set time needed',
  SET_TIME_NEEDED_DISABLED_TOOLTIP: 'Cannot set time need for the selected reading tasks.',
  ADD_PAGE_RANGE_DISABLED_TOOLTIP: 'Cannot add page ranges for the selected non reading tasks.',
  VIEW_COMPLETED_SUBTASKS: 'View completed subtasks',
  VIEW_ACTIVE_SUBTASKS: 'View active subtasks',
  NEW_TASK: 'NEW TASK',
  ACTION: 'ACTION',
  SET_START_DAYS_AHEAD: `Set "start days ahead"`,
  SEARCH: 'Search',
  RESET_COLUMNS_WIDTH: 'Reset column width',
  READING_TIME_NEED_TOOLTIP: `Time need for readings is calculated by multiplying your time per page by the number of pages you have to read`,
  VIEW_TASKS: 'View tasks',
  WITHIN_START_DATE: 'Within start date',
  ALL_TODO: 'To Do',
  HIDE_PLANNED_TASKS: 'Hide fully planned',
  SHOW_COMPLETED_TASKS: 'Show completed tasks\n(with a future due date)',
  VIEW_OPTIONS: 'View options',
  SEARCH_FOR_TASK: 'Search for task',
  MARK_ALL_COMPLETE: 'Mark all complete',
  MARK_COMPLETE_OVERDUE: 'Mark all overdue tasks as completed?',
  OVERDUE_TASKS: 'Overdue tasks',
  CLEAR_FILTER: 'Clear filter',
  FILTER_TASKS: 'Filter tasks',
  CLEAR_ALL_FILTERS: 'Clear all filters',
  SHOW_PLANNED_TASKS: 'Show planned tasks',
  HIDE_PLANNED_FILTER_TOOLTIP: 'Hide planned tasks is not applicable in Completed sections',
  FILTER_EMPTY: 'No tasks for filtering',
  MARK_COMPLETE_TOOLTIP: 'If you complete this task, all future planned time related to this task will be freed!',
  STUDY_TIME_AVAILABLE: 'Study time',
  IMPORT: 'Import',
  IMPORT_COURSE_DIALOG_TEXT:
    'If your friends are taking the same course as you and have already set it up in Shovel, they can export it and send it to you. This will save you time setting things up. Just do the same for them next term.',
  IMPORT_COURSE_DIALOG_EXTENSION_TEXT: 'Only .shovel formats can be imported.',
  CHOOSE_FILE: 'Choose file',
  DISMISS: 'Dismiss',
  CHARTS: 'Charts',
  ANALYTICS: 'Analytics',
  WORKLOAD_DUE_EACH_WEEK: 'Workload due each week',
  WORKLOAD_LEFT: 'Workload left',
  TASKS_COMPLETED: 'Tasks completed',
  TASKS_COMPLETED_EACH_WEEK: 'Tasks completed each week',
  OF_STUDYING_DONE: 'Of studying done',
  STUDYING_DONE_EACH_WEEK: 'Studying done each week',
  TOTAL: 'Total',
  CHOOSE_CUSTOM_COLOR: 'Choose custom color',
  CREATE_ONE_COURSE: 'Create at least one course',
  CREATE_ONE_ACTIVITY: 'Create at least one activity',
  SUBMIT_FEEDBACK: 'Submit feedback and request feature',
  SUBMIT_A_BUG: 'Submit a bug',
  FOLLOW_ONBOARDING: `Follow steps in the onboarding tour.`,
  CREATE_TASK_CATEGORY: 'Create a task category',
  CREATE_TASK_1: 'To create a task in',
  CREATE_TASK_2: ' you need to create a task category first.',
  ADD_TASK_CATEGORY_TITLE: 'Creating a task category in ',
  ADD_TASK_CATEGORY_SUBTITLE: 'What type of tasks will be in this category?',
  READING_TYPE_Q1: 'What will you be reading from?',
  READING_TYPE_Q2: 'How long will it take you to read ONE page from ',
  READING_TYPE_EX: 'Ex: Biology textbook, Single Spaced PDF',
  TEST_TYPE_Q: 'What do you need to study for?',
  TEST_TYPE_EX: 'Example: Weekly quizzes, Midterm exams, Final exam',
  ASSIGMENT_TYPE_Q: 'What do you need to submit?',
  ASSIGMENT_TYPE_EX: 'Example: Weekly Problem Sets, Essays, Lab reports',
  UNGRADED_TYPE_Q: 'What do you need to do?',
  UNGRADED_TYPE_EX: 'Example: Weekly Review, Practice Questions, Watch Lectures',
  READING_TYPE_LABEL: 'Title of a book or a PDF format',
  READING_TYPE_PLACEHOLDER: 'Ex: Biology textbook',
  NON_READING_TYPE_LABEL: 'Task category name',
  TEST_TYPE_PLACEHOLDER: 'Ex: Weekly quizzes',
  ASSIGMENT_TYPE_PLACEHOLDER: 'Ex: Weekly Problem Sets',
  UNGRADED_TYPE_PLACEHOLDER: 'Ex: Weekly Review',
  TIME_PER_PAGE_PLACEHOLDER: 'Time per page of this reading source',
  CREATE_CATEGORY: 'Create category',
  SELECT_TYPE: 'Select type',
  READING_TYPE_HINT_TITLE: `💡 A reading task category is the\nbook you'll be reading from or\nthe format of a PDF`,
  NON_READING_TYPE_HINT_TITLE: '💡 You can add individual tasks to\nthis category after you create it.',
  READING_TYPE_HINT_SUBTITLE: `The purpose is to group readings by their\n"time per page" (next step).`,
  TEST_TYPE_HINT_SUBTITLE: `For example, you can have:\n• 15 quizzes in a "Weekly quizzes" category`,
  ASSIGMENT_TYPE_HINT_SUBTITLE: `For example, you can have:\n• 15 essays in a "Weekly essays" category`,
  UNGRADED_TYPE_HINT_SUBTITLE: `For example, you can have: \n• 45 lectures in a "Watch Lectures" category`,
  TIME_PER_PAGE_HINT_TITLE: `💡 Each reading source has a\ndifferent "time per page."`,
  TIME_PER_PAGE_HINT_SUBTITLE1: `For example:\n• Textbook - 8mins per page\n• Novel - 3mins per page`,
  TIME_PER_PAGE_HINT_SUBTITLE2: `When you change the "time per page" for\na Textbook, all future readings from that\ntextbook will be automatically adjusted.`,
  EMPTY_TASK_NO_COURSE_SELECTED: 'To create tasks, open a course in the sidebar and add task categories first.',
  CREATE_CATEGORY_TOOLTIP: 'To create a task category, open a course in the sidebar.',
  UNGRADED_TASKS: 'Ungraded tasks',
  REVERT_TO_INCOMPLETE: 'Revert to incomplete',
  CLOSE_INFO_BOX_TOOLTIP: 'Never show this hint again.',
  PILE_INFO_BOX: `To plan when you'll work on a task, drag it into an empty study time in the calendar.`,
  COURSE_1_INFO_BOX: `Drag and drop courses and activities into the calendar.`,
  COURSE_2_INFO_BOX: `Click on a course to highlight events of only one course. Unclick course to view all.`,
  COURSE_3_INFO_BOX: `Click on a course to see tasks from only that course. \n Unclick course to view all tasks.`,
  REVERTED_TO_INCOMPLETE: 'reverted to incomplete',
  COURSE_TASK_LIST_TOOLTIP: 'Click to filter by course.',
  COMMITMENT_CALENDAR_TOOLTIP: 'Drag to schedule',
  AVAILABLE_STUDY_TIME_EACH_WEEK: 'Available study time each week',
  REORDER: 'Reorder',
  REORDER_COURSES: 'Reorder your courses',
  REORDER_ACTIVITIES: 'Reorder your activities',
  REORDER_CATEGORIES: 'Reorder categories inside ',
  NO_REORDER_COURSE: 'You can not reorder empty course (fill the name)',
  NO_REORDER_ACTIVITY: 'You can not reorder empty activity (fill the name)',
  DURATION: 'Duration',
  ADJUST_AWAKE_TIME: 'Adjust Awake Time',
  EMPTY_PLAN_TASK: `You did not create any tasks yet. \n\nCreate some tasks first, then come back here and plan when you'll work on them.`,
  DISABLE_EXPAND_SIDEBAR: 'To plan tasks, navigate to calendar and then open this sidebar.',
  HINT: 'Hint',
  VERIFY_EMAIL_TITLE: 'We sent you a verification email',
  VERIFY_EMAIL_CHECK_INBOX: '👉 Check your inbox',
  HELP: 'Help',

  TASK_NAME: 'Task name',
  SET_START_AHEAD: 'Set when to get started',
  SET_START_AHEAD_DISABLED: 'To set when to get started, set\nthe due date of this task first.',
  SET_DUE_DATE: 'Set due date and time',
  ADD_PAGE_RANGE: 'Add pages',
  ADD_PAGE_RANGE_DISABLED: 'To add pages, select a\nreading source first.',
  ADD_PAGE_RANGE_INFO: 'Enter the amount of pages you’ll need to read. \nType as total or as a range. Press Enter to add.',
  ESTIMATE_TASK: 'Estimate task',
  SET_EXAM_DURATION: 'Set exam duration',
  SET_DURATION_NO_DUE: `To set exam duration, set \ndue date and time first`,
  SET_DURATION_NOT_EXAM: `To set the duration, check \nthe task as exam first`,
  CREATE_REPEAT_TASK_DISABLED: `To create a repeat task, set \nthe due date of this task first.`,
  SELECT_CATEGORY: 'Select category',
  CREATE_NEW_CATEGORY: 'Create a new category',
  ADD_SOURCE: 'Add source',
  START_AHEAD: 'Start ahead',
  CLEAR: 'Set to zero',
  PAGE_BASED_EST: 'Page based estimation',
  REPEAT_TASK: 'Repeat task',
  COPY_FOR_EACH_TASK: 'Copy for each repeating task:',
  REPEAT_PATTERN: 'Repeat pattern',
  CONTINUE_NUMBERING: 'Continue numbering task',
  TASK_ESTIMATE: 'Task estimate',
  TASK_ESTIMATE_INFO: 'Estimate how much study time you’ll \nneed to use to get this task done.',
  CONTINUE_NUMBERING_TOOLTIP: `We noticed a number at the\nend of your Task's name. We \ncan continue numbering repeat\ntasks sequentially.`,
  ENDS_REPEATING: 'Ends repeating',
  DATES_SET: 'Dates are set',
  CREATING_TASKS: 'Creating tasks ',
  ADD_NAME: 'Add name',
  ADD_GRADED_CATEGORY: 'Add grade unit',
  COURSE_DETAILS: 'Course Details',
  INFO: 'Info',
  EMOJI: 'Emoji',
  ADD_READING_SOURCE: 'Add reading source',
  TIME_ESTIMATE_DISABLED: `You choose a page-based estimation.\nIf you want to enter a time\nestimate, go back and uncheck box\nin the 'Estimate Task' field.`,
  GRADE_UNIT_NAME: 'Grade unit name',
  DELETE_GRADE_UNIT: 'Delete grade unit',
  DELETE_TASK_CATEGORY: 'Delete task category',
  DELETE_READING_SOURCE: 'Delete reading source',
  DELETE_GRADE_UNIT_CONFIRMATION: 'You are about to delete grade unit',
  DELETE_TASK_CATEGORY_CONFIRMATION: 'You are about to delete task category',
  DELETE_READING_SOURCE_CONFIRMATION: 'You are about to delete reading source',
  DELETE_GRADE_UNIT_WARNING: 'This will delete all grades associated with it.',
  DELETE_TASK_CATEGORY_WARNING: 'It will disappear from all associated tasks if any.',
  DELETE_READING_SOURCE_WARNING:
    'It will disappear from all associated tasks if any. That will also affect time estimation for these tasks.',
  ESTIMATE_TASK_DISABLED: 'Task estimate will be calculated\nautomatically after you enter pages',
  ESTIMATE_TASK_DISABLED_FILLED:
    'Task estimate is being calculated automatically\nusing time per page of the reading source\nand the number of pages you need to read',
  NO_TASK_NAME_HINT: '💡 Hint: Add a task name for each task',
  PLANNED_TAG_TOOLTIP:
    'To plan when you’ll work on this task, go to Calendar, click “Plan” in top right, and drag the task from the right sidebar into the calendar.',
  NO_DUE_DATE: 'No due date',
  NO_CATEGORY: 'No category',
  NO_SOURCE: 'No reading source',
  DELETE_TEACHER: 'Delete teacher',
  ADD_CALENDAR: 'Add calendar',
  PICK_EMOJI: 'Pick your emoji',
  NOT_FOUND: 'No Emoji Found',
  SKIN_TEXT: 'Choose your default skin tone',
  SEARCH_RESULT: 'Search Results',
  RECENT: 'Frequently Used',
  SMILEYS: 'Smileys & Emotion',
  PEOPLE: 'People & Body',
  NATURE: 'Animals & Nature',
  FOODS: 'Food & Drink',
  PLACES: 'Travel & Places',
  OBJECTS: 'Objects',
  SYMBOLS: 'Symbols',
  FLAGS: 'Flags',
  CUSTOM: 'Custom',
  EMOJI_CAT_LABELS: 'Emoji categories',
  DEFAULT_SKIN_TONE: 'Default Skin Tone',
  LIGHT_SKIN_TONE: 'Light Skin Tone',
  MED_LIGHT_SKIN_TONE: 'Medium-Light Skin Tone',
  MEDIUM_SKIN_TONE: 'Medium Skin Tone',
  MED_DARK_SKIN_TONE: 'Medium-Dark Skin Tone',
  DARK_SKIN_TONE: 'Dark Skin Tone',
  NOT_PLANNED: 'Not planned',
  SELECT_COURSE_OR_ACTIVITY_TOOLTIP: 'Select course or activity first',
  CHANGE_EMOJI: 'Change emoji',
  EDIT_READING_SOURCE: 'Edit reading source',
  NO_TASKS: 'Not possible to create tasks in selected repeat pattern.',
  EMPTY_TASK_LIST_TEXT_1: 'What do you need to get done this semester?',
  EMPTY_TASK_LIST_TEXT_2: 'Hint: It’s in your syllabus',
  EMPTY_RANDOM_TASK_LIST_TEXT_1: 'Keep your random tasks here',
  EMPTY_RANDOM_TASK_LIST_TEXT_2: 'You can always assign them to a course later.',
  OR: 'or',
  NO_REPEAT: `Don't repeat`,
  SET_DATES: 'Set dates',
  WATCH: '👇 Watch ',
  SECONDS: 'seconds',
  BEFORE_ANSWERING: 'before answering the question below',
  GRADING_RUBRIC: 'Grading rubric',
  INCLUDE_SCHEDULE_TOOLTIP: 'Uncheck if you already set up your schedule for this course',
  INCLUDE_TEACHERS_TOOLTIP: "Uncheck if you don't have the same teachers",
  LEFT_TO_PLAN: 'Left to plan',
  SORT_BY: 'Click to sort',
  RESIZE_COLUMN: 'Resize column',
  WITHIN_START_DATE_EMPTY_MESSAGE: `No tasks "Within the start date" found`,
  WITHIN_START_DATE_EMPTY_MESSAGE_WITH_FILTER: `No tasks "Within the start date" match this filter and/or search`,
  ALL_TO_DO_EMPTY_MESSAGE: `No tasks in "All to do" found`,
  ALL_TO_DO_EMPTY_MESSAGE_WITH_FILTER: `No tasks in "All to do" match this filter and/or search`,
  COMPLETED_EMPTY_MESSAGE: `No tasks in "Completed" found`,
  COMPLETED_EMPTY_MESSAGE_WITH_FILTER: `No tasks in "Completed" match this filter and/or search`,
  CALCULATE_CUSHION: 'Calculate cushion',
  CALCULATE_CUSHION_MESSAGE: 'To calculate the Cushion, click the purple button above.',
  NO_COURSE: 'No course',
  REMOVE_READING_SOURCE: 'Remove reading source',
  REMOVE_READING_SOURCE_WARNING: `Removing a reading source will reset your task's estimation and progress!`,
  CHANGE_TASK_CATEGORY: 'Change task category',
  MOVED_TO: 'moved to',
  CHANGE_TASK_CATEGORY_DISABLED_TOOLTIP: 'Cannot change category for task from multiple courses',
  REMOVE_TASK_CATEGORY: 'Remove task category',
  REMOVE_TASK_CATEGORY_MESSAGE: 'Task category will be removed from ',
  CHANGE_READING_SOURCE: 'Change reading source',
  CHANGE_READING_SOURCE_DISABLED_TOOLTIP: 'Cannot change reading source for task from multiple courses',
  REMOVE_READING_SOURCE_MESSAGE: 'Reading source will be removed from ',
  NO_COURSE_CATEGORY_DISABLED_TOOLTIP: 'Cannot add a category for tasks with no course',
  NO_COURSE_READING_DISABLED_TOOLTIP: 'Cannot add a reading source for tasks with no course',
  PAST_DUE: 'Past due',
  CLICK_TO_VIEW_STEP: 'Click to view step #',
  LINK_GOOGLE_CALENDAR_WITH_COURSE: 'Select the calendar you want to\nsync with ',
  LINK_GOOGLE_CALENDAR_WITH_THIS_COURSE: 'Select the calendar you want to sync with this course',
  GOOGLE_CALENDAR_SELECT_COURSES: 'Select courses you want to add',
  LINK_TO_G_CALENDAR: 'Link Google cal.',
  UNSYNC_FROM: 'Unsync from',
  UNSYNC_CONFIRMATION_MESSAGE: (platforms: string) => `Events and tasks synced with ${platforms} will be deleted.`,
  UNLINK_FROM_G_CALENDAR: 'Unlink Google cal.',
  LINK_TO_GOOGLE_CALENDAR: 'Link to Google calendar',
  UNLINK_FROM_GOOGLE_CALENDAR: 'Unlink from Google calendar',
  FIELD_REQUIRED: 'Field is required',
  TASK_NAME_REQUIRED: 'Task name is required',
  UNLINK: 'Unlink',
  UNSYNC: 'Unsync',
  UNLINK_COURSE_TITLE: (googleCalendarName: string, courseName: string) =>
    `Unsync ${googleCalendarName} from ${courseName || 'this course'}`,
  UNLINK_COURSE_MESSAGE:
    'Events linked to this calendar will be deleted. Tasks linked to this calendar will be moved to trash.',
  FOCUS: 'Focus',
  ADD_NOTES: 'Add notes',

  SCHEDULE_CLASS: 'Schedule a class',
  DEMO_COURSE: 'Demo course',
  DEMO_CALENDAR: 'Demo calendar',
  DEMO_TASK: 'Demo task',
  AVAILABLE_STUDY_TIME: 'Available study time',
  DEMO_COURSE_HINT: 'Drag course into the calendar to schedule class time.',
  DEMO_TASK_HINT_ONE: `A due date is not a "DO" date. \nTo plan when you'll work on a task, drag it into an empty study time in the calendar.`,
  DEMO_TASK_HINT_TWO: `RESIZE OR DRAG IN AGAIN\nThis task is estimated for 2 hours. You planned one hour. Drag it in again or resize the task in the calendar.`,
  START_DIGGING: `Start digging`,
  WELL_DONE: 'Well done, ',
  WELL_DONE_SUBTITLE: 'Now you know how to get things done.',
  DEMO_INTRO: `This is a demo of how Shovel works.`,
  EFFECTIVELY: 'Effectively.',
  LETS_SETUP: `Let’s set up some basics...`,
  PLAN_WORKING_ON_TASK: 'Plan when you will work on your task',
  FAILED_TO_CONNECT: 'Failed to connect. Click on + Add calendar to try again.',
  RESET_TRIAL_SUCCESS: 'Your trial has been reset',
  //rich text editor
  SIZE: 'Size',
  BOLD: 'Bold',
  ITALIC: 'Italic',
  UNDERLINE: 'Underline',
  STRIKE: 'Strikethrough',
  BULLET_LIST: 'Bulleted list',
  NUM_LIST: 'Numeric list',
  QUOTE: 'Quote',
  INSERT_LINK: 'Insert a link',
  FONT_COLOR: 'Font color',
  TXT_BG: 'Text background',
  ALIGN: 'Align text',
  INSERT_EMOJI: 'Insert emoji',
  CLEAR_FORMAT: 'Clear format',
  MAX_CHAR_ERROR: 'Maximum limit of allowed characters is reached',
  HEADING1: 'Heading 1',
  HEADING2: 'Heading 2',
  HEADING3: 'Heading 3',
  NORMAL: 'Normal',
  LINK_PLACEHOLDER: 'Type url and press enter',
  TRASH: 'Trash',
  RANDOM: 'Random',
  SUBSCRIBING_TEXT: (firstName?: string) => `Hi ${firstName || ''}\n
By subscribing, your Shovel will keep getting more powerful features and we’ll be able to get some coffee for the team. \n\nThank you and keep digging!\n
- Your Shovel team
  `,
  CONTINUE_WITH: 'Continue with',
  RESTORED: 'Restored',
  RESTORE: 'Restore',
  RESTORE_ALL: 'Restore all',
  NO_TRASH_TASKS: '👻 No tasks in trash',
  TRASH_30_DAYS: '💡 Tasks in trash will be kept for 30 days, just in case you change your mind.',
  EMPTY_TRASH: 'Empty trash',
  EMPTY_TRASH_NOW: 'Empty trash now',
  RESTORE_ALL_CONFIRMATION: 'Are you sure you want to restore all tasks?',
  EMPTY_TRASH_CONFIRMATION: `Are you sure you want to delete all tasks? You won't be able to restore them!`,
  HELP_US_IMPROVE_TITLE: `So sorry to see you go 😢`,
  HELP_US_IMPROVE_SUBTITLE: `Tell us how to improve, we\'ll listen.`,
  HELP_US_IMPROVE_DESCRIPTION:
    "Literarily. Your feedback will be read out loud during our team meeting. Don't hold back and tell us what we could have improved for you.",
  HELP_US_IMPROVE_PLACEHOLDER: "I didn't enjoy Shovel because...",
  CHANGE_COURSE: 'Change list',
  CHANGE_COURSE_CONFIRMATION: (
    <div>
      Changing the list will remove task's reading source and category! <br />
      Removing the reading source will reset your task's estimation and progress!
      <br />
      Are you sure you want to continue?
    </div>
  ),
  RECENTLY_CREATED: 'Recently created',
  CREATED_AT: 'Created at',
  DRAG_TO_PLAN: 'Drag to plan',
  LIST: 'List',
  DAILY: 'Daily',
  WEEKLY: 'Weekly',
  FREE: 'Free',
  FREE_EVENT_TOOLTIP: 'If you can study during this event, mark it as “free.”',
  YOU_HAVE_SUCCESSFULLY_SUBSCRIBED: 'You have successfully subscribed',
  MONDAYS: 'Mondays',
  TUESDAYS: 'Tuesdays',
  WEDNESDAYS: 'Wednesdays',
  THURSDAYS: 'Thursdays',
  FRIDAYS: 'Fridays',
  SATURDAYS: 'Saturdays',
  SUNDAYS: 'Sundays',
  HIDE_FULLY_PLANNED: 'Hide fully planned',
  SHOW_FULLY_PLANNED: 'Show fully planned',
  GOOGLE_TASK_DUE_DATE_INPUT_TOOLTIP: 'Set by your teacher and cannot be edited.',
  ON: 'on',
  CHANGE_REPEAT_TITLE: '⚠️ Changing a repeat pattern',
  REMOVE_REPEAT_TITLE: '⚠️ Removing a repeat pattern',
  REMOVE_REPEAT_CONFIRMATION: 'All other occurrences will be removed!',
  DELETE_GOOGLE_TASK_TOOLTIP: 'Task from Google cannot be deleted.',
  RESTART_TRIAL: 'Restart trial',
  SELECT_COURSE_OR_ACTIVITY: 'Select course or activity',
  DUE_AT_ACTIVITY_START_LABEL: 'Due at activity start @ ',
  TYPICAL_DAY_START_TOOLTIP: `Your calendar view will scroll to\nthis time so you don’t have to :)`,
  JOIN_REDDIT: 'Join our Reddit community',
  LEARN_ABOUT_FEATURES: 'Learn about features',
  NO_LIST: 'No list',
  START_AHEAD_FOR_TASKS: 'Start ahead for tasks',
  DEFAULT_START_AHEAD: 'Default (Start days ahead)',
  DEFAULT_START_AHEAD_TOOLTIP: `Set default for how many days\nbefore the due date you want to\nstart working on a task.`,
  SHOW_DEADLINE_FLAGS: `Show deadline flags in calendar `,
  CALENDAR_VIEWPORT: 'Calendar viewport',
  SET_DEFAULT: 'Set default',
  SWITCH_TO_LIFETIME: 'UPGRADE YOUR PLAN TO LIFETIME FOR ONLY ',
  CONTINUE_TO_LIFETIME: 'Continue to Upgrade to Lifetime',
  CONTINUE_TO_LIFETIME_PAYPAL: 'Continue with PayPal to upgrade to lifetime',
  LIFETIME_MEMBERSHIP_MESSAGE: 'You have purchased Lifetime access to Shovel',
  CHANGE_COURSE_NOTIFICATION: (taskNum: number, courseName: string) =>
    `${taskNum} Task${taskNum !== 1 ? 's' : ''} Moved to ${courseName}`,
  GO_BACK_TO_HOME: 'Go back to home',
  PRICING_OPTIONS_TITLE: 'A Small Price to Pay for a Better GPA',
  PRICING_OPTIONS_TEXT: 'Better grades. Less stress. More fun 💜',
  LESS_THAN_MONTHLY: 'less than monthly',
  ENTER_IT_HERE: 'Enter it here',
  HAVE_A_COUPON: 'Have a coupon?',
  LIFETIME_IMAGE_TEXT: 'Not much for you to do here. You have Shovel for life!\n' + 'Get back to timeboxing 😊',
  TRIAL_EXPIRED: 'Your trial has expired',
  DAYS_OF_FREE_TRIAL_LEFT: daysLeft => `${daysLeft} ${daysLeft !== 1 ? 'day' : 'days'} of free trial left`,
  CARD_OR_APPLE_GOOGLE_PAY: 'Card, Apple/Google Pay',
  PAYPAL: 'PayPal',
  HAVE_PARENTS_PAY: 'Have your parents pay',
  COPY_PARENT_LINK: 'Copy Parent Payment Link',
  PARENT_LINK_TEXT:
    'Your parents will gladly pay for you to get better grades. Send them the link below and they can unlock your account for you:',
  SELECT_PAYMENT_METHOD: 'Select Payment Method',
  PARENT_LINK_TITLE: `Give the Gift of Better Grades`,
  PARENT_LINK_SUBTITLE: `Your student wants to get better grades and be more productive. You've\nraised a go-getter. Congrats!`,
  PARENT_LINK_INFO: (
    <>
      The biggest cause of stress and anxiety for students is poor <span style={{ color: PINK_COLOR }}>time management</span>
      . Shovel Study Planner fixes that in a unique way.
      <br />
      <br />
      Shovel combines your student's calendar with their class syllabi, so they can{' '}
      <span style={{ color: PINK_COLOR }}>timebox</span> what they will work on, when, and for how long.
      <br />
      <br />
      Additionally, no other app has our <span style={{ color: PINK_COLOR }}>special algorithm</span> that will let your
      student see into their future. Our "Cushion" is designed to warn them in advance if they won't have enough time to
      complete an assignment before it's deadline, so they can adjust their schedule before it's too late.
      <br />
      <br />
      With Shovel, your student will:
    </>
  ),
  PARENT_LINK_BULLETS: (
    <ul>
      <li>
        <span>Reduce procrastination</span>
      </li>
      <li>
        <span>Reduce stress</span>
      </li>
      <li>
        <span>Increase focus</span>
      </li>
      <li>
        <span>Never miss a deadline</span>
      </li>
      <li>
        <span>Get better grades</span>
      </li>
    </ul>
  ),
  LEARN_MORE_ABOUT_SHOVEL: (
    <span>
      To learn more about Shovel, go{' '}
      <a href="https://howtostudyincollege.com/shovel-study-planner" target="_blank">
        here.
      </a>
    </span>
  ),
  SELECT_PLAN_PAY_METHOD: `Select your plan and payment method and\nunlock your student's learning potential.`,
  UNLOCK_STUDENT_POTENTIAL: `Unlock your student's\nlearning potential.`,
  PARENT_SUBSCRIBING_TEXT: `Hello,\n\nBy getting Shovel for your student, you will give\nthem a gift of a better future. The time management\ntechnique they will learn with Shovel will stay with\nthem for the rest of their life.\n\n- Your Shovel team`,
  PAYMENT_SUCCESS: 'Payment was successful 🎉',
  PAYMENT_SUCCESS_STUDENT: 'Thank you for trusting us with your productivity!',
  PAYMENT_SUCCESS_PARENT: `Your student's Shovel account has been unlocked.`,
  PAYMENT_SUCCESS_PARENT_TEXT: `Thank you for trusting us with your student's productivity.`,
  GO_BACK_HOME: 'Go back home',
  PARENT_LINK_COPIED: 'Parent link copied',
  SELECT_PAY_PLAN: 'Select Payment Plan',
  SUBSCRIPTION_EXISTS: `Subscription already exists.\nYour student can manage it in their Shovel account.`,
  ANNUAL: 'Annual',
  MOST_POPULAR: 'Most Popular',
  MARK_TASK_COMPLETE: 'Mark Task Complete',
  ACTUAL: 'Actual',
  MARK_TIME_AS_USED: 'Mark time as used',
  UNPLAN_TIME: 'Unplan from this time',
  OPEN_MAIN_TASK: 'Open main task',
  MY_PREFERENCES: 'My preferences',
  CHOOSE_COLOR_SCHEME: 'Choose color scheme',
  LAVENDER: 'Lavender',
  TANGERINE: 'Tangerine',
  FLAMINGO: 'Flamingo',
  SAPPHIRE: 'Sapphire',
  PICK_CUSTOM: 'Pick custom',
  SELECT_GOOGLE_CALENDAR_NOTIFICATION: 'If the calendar had tasks, they will appear in your Task List ',
  UNSELECT_GOOGLE_CALENDAR_NOTIFICATION: 'If the calendar had tasks, they were deleted.',
  TIME_USED: 'Time used',
  PLANNED_FOR: 'Planned for',
  ADD_TIME_ENTRY: 'Add time entry',
  REVERT_TO_UNUSED: 'Revert to unused time',
  PREVIOUS_ESTIMATE: 'Previous estimate',
  TODAY_AVERAGE: `Today's average`,
  USE_TODAY_AVG_AS_NEW_EST: `Use today's average as new estimate`,
  KEEP_OLD_EST: 'Keep the old estimate',
  OVERALL_AVG: 'Overall avg',
  TIME_MARKED_AS_USED: 'Time marked as used',
  START_TIMER: 'Start timer',
  RESET_TIMER: 'Reset timer',
  PAUSE_TIMER: 'Pause timer',
  TIMER_NOT_ACTIVE: 'Timer is not active',
  RESET_TIMER_MESSAGE: 'Are you sure you want to reset your timer?',
  DELETE_PLAN_TASK: 'Delete planned task',
  UNPLAN_TASK: 'Unplan task',
  DELETE_PLAN_TASK_CONFIRMATION: 'Are you sure you want to delete this planned task?',
  UNPLAN_TASK_CONFIRMATION: 'Are you sure you want to unplan the task from this time?',
  PAST_PLAN_TASK_TOOLTIP: 'Time has passed and does not effect your planned time. Check it off as done to add progress.',
  TIME_PLANNED: 'Time planned',
  WILL_WORK: 'I will work on..',
  GO_TO_TIMER: 'Go to active timer',
  SIGN_UP_MOBILE: `👉 Sign Up using your\ncomputer so you can plan\nefficiently.`,
  SIGN_UP_MOBILE_INFO: `After you start on your\ncomputer, you can download\nthe app and shovel on the go.`,
  MOBILE_SIGNUP_INSTRUCTIONS: (
    <div>
      <div style={{ marginBottom: '10px' }}>Instructions sent to Email</div>
      <div>Thank you, we sent you an email with instructions on how to get started with Shovel.</div>
    </div>
  ),
  RESEND_ACTIVATION_EMAIL: 'Resend Activation Email',
  SIGN_UP_WITH_DIFFERENT_EMAIL: 'Sign Up with a Different Email',
  RESEND_ACTIVATION_EMAIL_TEXT:
    'If we recognize that you signed up with this email address, we’ll resend you the activation email.',
  RESEND_ACTIVATION_EMAIL_SUCCESS: (email: string) => `Activation email sent successfully to ${email}`,
  INSTRUCTIONS_SENT_TO: 'Instructions sent to',
  MOBILE_SIGNUP_INSTRUCTIONS_TEXT: (
    <div style={{ fontSize: '21px' }}>
      👉 Please open the activation email on your <span style={{ color: '#F9C941' }}>computer</span> to get started
    </div>
  ),
  CREATE_YOUR_COURSES: 'Create your courses',
  COURSE_SETUP_DESCRIPTION: `Watch This Important Video 👇`,
  ADD_COURSES_FROM: 'Where do you want to add a course from?',
  FRIENDS_WHO_USE_SHOVEL: 'Friends who use Shovel',
  ADD_MANUALLY: 'Add manually',
  EDIT_COURSE_COLOR_AND_NAME: 'You can edit course color and name',
  SYNC: 'Sync',
  PASTE_LINK: platform => `Paste ${platform} Calendar Link Below 👇`,
  PASTE_LINK_PLACEHOLDER: `Paste Calendar Feed URL`,
  LINK: 'Link',
  COURSES_FOUND_IN_YOUR: 'Courses found in your',
  ICS_PARSED_DESCRIPTION: (
    <span>
      Select which courses you want to connect to and add them to
      <br /> existing courses if you don’t want to create a new course.
    </span>
  ),
  ITEMS: 'items',
  FOUND: 'found',
  PARSED_COURSES: 'Parsed courses',
  CONNECT: 'Connect',
  FRIEND: 'Friend',
  SYNC_WITH: 'Sync with',
  ADD_TO_EXISTING_COURSE: 'Add to an existing course',
  SELECT_COURSES: 'Select Courses',
  SELECT_COURSE_TO_CONNECT: 'Select course to connect to',
  SCROLL_TO_LEARN_HOW: 'Scroll to learn how to...',
  // TODO: check with Petr for tooltip text
  FILTER_CALENDAR: 'Filter',
  WHAT_IS_SHOVEL: 'What is Shovel?',
  UPDATE_IMPORTED_EVENT_TITLE: 'Update imported event',
  UPDATE_IMPORTED_EVENT_MESSAGE:
    'This event was created by your professor and updating it will be reflected only in Shovel.',
  DELETE_IMPORTED_EVENT_TITLE: 'Delete imported event',
  DELETE_IMPORTED_EVENT_MESSAGE: (
    <span>
      This event was created by your professor and deleting it will be reflected only in Shovel.
      <br /> This action cannot be undone.
    </span>
  ),
  NEW_ICS_CHANGES: 'New changes in your synced calendars',
  ICS_CHANGES_TEXT: 'We noticed some changes in your linked calendars and updated Shovel automatically.',
  VIEW_CHANGES: 'View Changes',
  CONNECT_NEW_COURSES: 'Connect New Courses?',
  CONNECT_NEW_COURSES_TEXT:
    'Select if you want to connect to new courses and whether you want to add them to existing courses in Shovel.',
  ICS_DELETED_COURSES: 'Deleted Course(s)',
  ICS_DELETED_COURSES_WARNING: 'Every item that was in this course will be deleted from',
  ICS_UPDATED_ITEMS: 'Updated Items',
  NAMES_CHANGED: 'Names Changed',
  DESCRIPTIONS_CHANGED: 'Descriptions Changed',
  DEADLINES_DATES_CHANGED: 'Deadlines/Dates Changed',
  NEW_ITEMS: 'New items',
  DELETED_ITEMS: 'Deleted items',
  COURSES_NOT_CONNECTED_TOOLTIP: 'Select whether you want to connect new courses or not',
  NEW_SHOVEL_V_TITLE: '🎉 New Shovel version available',
  NEW_SHOVEL_V_MSG: 'Click update to get the latest version',
  UPDATE: 'Update',
  UPDATE_SUCCESS: '🎉 Update successful',
  RECONNECT: 'Reconnect',
  GOOGLE_CAL_ERROR: 'Google calendar sync error',
  GOOGLE_CAL_ERROR_MSG: 'Something has gone wrong, please reconnect your Google calendar.',
  SOMETHING_WENT_WRONG: 'Something has gone wrong, please reload and try again',
  FIREFOX_TIME_ISSUE_TITLE: 'Firefox error. Please switch browser.',
  FIREFOX_TIME_ISSUE_MESSAGE:
    'Firefox does not support this action on some devices. Please continue on a different browser to finish setup. You can switch back to Firefox once setup is complete.',
  DEFAULT_ACTIVITIES: 'Start with default activities',
  COPY_ACTIVITIES: 'Copy from previous semester',
  COPY_ACTIVITY_NAMES: 'Activity names only',
  COPY_ACTIVITY_NAMES_AND_SCHEDULE: 'Activity names and scheduled events',
  UNTIL: 'Until',
  EXAM_DATE: 'Exam date',
  EXAM: 'Exam',
  WHITE: 'white',
  GRAY: 'gray',
  ERROR: 'Error',
  SUCCESS: 'Success',
  LOADING: 'Loading',
  YOU_ROCK: 'You Rock!',
  TASK_STATUS_SAVED: 'Task Progress Saved',
  TASK_CATEGORY_TASK_LIST_TOOLTIP: 'Click to filter by task category.',
  UNCATEGORIZED: 'Uncategorized',
  REORDER_COLUMNS: 'Reorder columns',
  ON_SELECTED_DAYS: 'On selected days',
  DONE_WITH_ENTIRE_TASK: 'Done with the entire task?',
  SELECT_INDIVIDUAL_DAYS: 'Select individual days',
  DAY_SELECTED: 'day selected',
  DAYS_SELECTED: 'days selected',
  CALENDAR_STYLING: 'Calendar styling',
  PLANNED_TASKS: 'Planned tasks',
  COURSE_EVENT_TITLE_EXAMPLE: 'Math',
  ACTIVITY_EVENT_TITLE_EXAMPLE: 'Lunch',
  GOOGLE_EVENT_TITLE_EXAMPLE: 'Meeting',
  PLANNED_TASK_TITLE_EXAMPLE: 'Psychology',
  PLANNED_TASK_SUBTITLE_EXAMPLE: 'Blood I',
  ALL_DEADLINES: 'All deadlines',
  EXAMS: 'Exams',
  DATE_COMPLETED: 'Date completed',
  LAST_30_DAYS: 'Last 30 days',
  LAST_WEEK: 'Last week',
  THIS_WEEK: 'This week',
  NEXT_WEEK: 'Next week',
  NEXT_7_DAYS: 'Next 7 days',
  SORT_TASKS: 'Sort tasks',
  TOTAL_ESTIMATE: 'Total estimate',
  TIME_STILL_NEEDED: 'Time still needed',
  TIME_LEFT_TO_PLAN: 'Time left to plan',
  HIGHEST_FIRST: 'Highest first',
  LOWEST_FIRST: 'Lowest first',
  SOONEST_FIRST: 'Soonest first',
  LATEST_FIRST: 'Latest first',
  CLOSEST_TO_NOW_FIRST: 'Closest to now first',
  FURTHEST_FROM_NOW_FIRST: 'Furthest from now first',
  NEWEST_FIRST: 'Newest first',
  OLDEST_FIRST: 'Oldest first',
  PRIORITY: 'Priority',
  SET_PRIORITY: 'Set priority',
  HIGH: 'High',
  MEDIUM: 'Medium',
  LOW: 'Low',
  RESET_SORTING: 'Reset sorting',
  NEW_EVENT: 'New event',
  CREATE_NEW_TASK: 'Create a new task',
  PLAN_EXISTING_TASK: 'Plan existing task',
  PLAN_FOR: 'Plan for',
  CREATE_AND_PLAN: 'Create and plan',
  PLAN_TASK_CREATE_RECURRING_WARNING:
    '⚠️ Recurring tasks will be created, but ONLY THE FIRST will be “planned” into the calendar',
  EXAM_TIME: 'Exam time',
  THIS_IS_EXAM_TIME: 'Yes, this is when I will be taking the exam',
  I_WILL_STUDY_FOR_EXAM: 'No, this is when I plan to study for the exam',
  SERVER_ERROR: 'Oops, something broke.',
  SERVER_ERROR_MESSAGE: "Don't worry, your account is safe and we're working on a fix.",
  RELOAD: 'Reload',
  SHOVEL_COURSES: 'Shovel Courses',
  COURSES_WEBSITE_LINK_HINT_1_TITLE: 'Discover your courses',
  COURSES_WEBSITE_LINK_HINT_1_TEXT: 'You have access to exclusive courses. To view them, click this icon.',
  DEFAULT_DAYS_AHEAD_ERROR_MESSAGE: 'Default start days ahead must be set',
  ONE_OR_MORE_TASKS_ARE_MISSING_DUE_DATE: 'One or more selected tasks are missing due date',
  RESUBSCRIBE: 'Resubscribe',
  BACK_TO_SHOVEL_COACH: 'Back to Shovel Coach',
  GIVE_ACCESS: 'Give Access',
  GIVE_ACCESS_PAGE_TITLE: 'Give Access to Others to Manage Your Account',
  GIVE_ACCESS_PAGE_TEXT: (coachAppUrl: string) => {
    return (
      <>
        If you're working with someone who is helping you stay organized, you can give them permission to manage your Shovel
        account. They just need to create a{' '}
        <a href={coachAppUrl} style={{ color: '#786FFF', textDecoration: 'none' }}>
          coaching account
        </a>{' '}
        themselves. If they already have one, enter their coach ID below.
      </>
    );
  },
  COACH_ID: 'Coach ID',
  REVOKE_ACCESS: 'Revoke Access',
  GIVE_ACCESS_SUCCESS: 'Access Granted Successfully',
  REVOKE_ACCESS_SUCCESS: 'Access Revoked Successfully',
  COACH_DOESNT_EXIST: (accessCode: string) => `Coach with access code ${accessCode} doesn\'t exist`,
  COACH_ID_PLACEHOLDER: '8-char ID',
  SHARE_CALENDAR: 'Share Calendar',
  COPY: 'Copy',
  COPY_CALENDAR_URL: name => `Copy ${name} Calendar Link 👇`
};

export default t;
